<template>
  <div class="about">
    <el-container style="height: 500px; border: 1px solid #eee">
      <el-header style="text-align: right; font-size: 12px">
        <tou></tou>
      </el-header>
      <el-container>
        <el-aside width="180px" style="background-color: rgb(238, 241, 246)">
          <leftside></leftside>
        </el-aside>
        <el-main>
          <el-date-picker
            v-model="start"
            type="date"
            placeholder="开始日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            style="width: 100px"
          >
          </el-date-picker>
          至
          <el-date-picker
            v-model="end"
            type="date"
            placeholder="结束日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            style="width: 100px"
          >
          </el-date-picker>
          <label for="" style="margin-left: 20px">id：</label>
          <el-input placeholder="请输入id" v-model="id" style="width: 150px" clearable>
          </el-input>
          <label for="" style="margin-left: 20px">用户姓名：</label>
          <el-input
            placeholder="请输入内容"
            v-model="nameinput"
            style="width: 170px"
            clearable
          >
          </el-input>
          <label for="" style="margin-left: 20px">店铺名称：</label>
          <el-select v-model="shopname" filterable @change="zt">
            <el-option :name="0" label="全部" :value="0"> </el-option>
            <el-option
              v-for="item in option"
              :key="item.shop_id"
              :name="item.shop_id"
              :label="item.shop_name"
              :value="item.shop_id"
            >
            </el-option>
          </el-select>
          <label for="" style="margin-left: 20px">收银员：</label>
          <el-input
            placeholder="请输入内容"
            v-model="syinput"
            style="width: 170px; margin-top: 3%"
            clearable
          >
          </el-input>
          <label for="" style="margin-left: 20px">用户账号：</label>
          <el-input
            placeholder="请输入内容"
            v-model="input"
            style="width: 170px"
            clearable
          >
          </el-input>
          <label for="" style="margin-left: 20px">卡号：</label>
          <el-input
            placeholder="请输入内容"
            v-model="cardinput"
            style="width: 170px"
            clearable
          >
          </el-input>
          <el-button type="warning" @click="cha" style="margin-left: 20px"
            >查看</el-button
          >
          <br /><br />
          <!-- 内容区域 -->
          <Breadcrumb></Breadcrumb>
          <h5>总充值金额：{{ lingshou }}元</h5>
          <!-- 表格 -->
          <div class="table">
            <el-table :data="tableData" border style="width: 100%">
              <el-table-column fixed prop="log_id" label="ID"> </el-table-column>
              <el-table-column fixed prop="sy_nickname" label="收银员"> </el-table-column>
              <el-table-column prop="nickname" label="用户名"> </el-table-column>
              <el-table-column prop="cardnum" label="卡号"> </el-table-column>
              <el-table-column prop="account" label="手机号"> </el-table-column>
              <el-table-column prop="create_time" label="充值时间"> </el-table-column>
              <el-table-column prop="collection_money" label="收款金额">
              </el-table-column>
              <el-table-column prop="money" label="充值金额"> </el-table-column>
              <el-table-column prop="recharge_money_brfore" label="充值前金额">
              </el-table-column>
              <el-table-column prop="recharge_money_after" label="充值后金额">
              </el-table-column>
              <el-table-column prop="shop_name" label="网点"> </el-table-column>
            </el-table>
          </div>
          <!-- /End 表格 -->
          <!-- <div>
				<el-popover placement="left" width="780px" trigger="manual" :offset="300" v-model="visible">
					<div class="spbiao">
						<el-button slot="reference" @click="visible = !visible" style="float:right"><i class="el-icon-close"></i></el-button>
						<el-table :data="gridData" height="520">
							<el-table-column property="id" label="编码"></el-table-column>
							<el-table-column property="title" label="名称"></el-table-column>
							<el-table-column property="photo" label="商品">
								<template slot-scope="scope">
									<img :src="scope.row.photo" width="40" height="40">
								</template>
							</el-table-column>
							<el-table-column property="price" label="单价"></el-table-column>
							<el-table-column property="num" label="数量"></el-table-column>
							<el-table-column property="total_price" label="总价"></el-table-column>
						</el-table>

					</div>
				</el-popover>
			</div> -->

          <!-- <Pagination></Pagination> -->

          <!-- 分页 -->
          <div class="pagination">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[20, 40, 60]"
              :page-size="pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
          <!-- /End 分页 -->
        </el-main>
      </el-container>
    </el-container>
    <!-- <el-date-picker v-model="start" type="date" placeholder="开始日期">
		</el-date-picker> 至
		<el-date-picker v-model="end" type="date" placeholder="结束日期">
		</el-date-picker> -->
    <!-- <label for="" style="margin-left:20px">手机号：</label>
		<el-input placeholder="请输入内容" v-model="input" style="width:200px" clearable>
		</el-input> -->
    <!-- <label for="" style="margin-left:20px">卡号：</label>
		<el-input placeholder="请输入内容" v-model="cardinput" style="width:200px" clearable>
		</el-input> -->

    <!-- /End 内容区域 -->
  </div>
</template>

<script>
import leftside from "../components/leftside.vue";
import tou from "../components/tou.vue";
// import axios from "axios";
export default {
  name: "vipcardinfo",
  components: {
    leftside,
    tou,
  },
  data() {
    return {
      start: "",
      end: "",
      input: "",
      cardinput: "",
      nameinput: "",
      gridData: [],
      syinput: "",
      id: this.$route.query.id,
      shopname: "",
      myInstance: null, // axios实例
      tableData: [], // 表格数据
      visible: false,
      currentPage: 1, // 当前页码
      total: 0, // 总条目数
      query: "", // 查询参数
      pagenum: 1, // 当前页码
      pagesize: 20, // 每页显示条数
      option: [],
      sid: 0,
      lingshou: 0,
    };
  },
  mounted() {
    this.getUser();
    this.$axios.post(this.$api.shoplist, {}).then((res) => {
      this.option = res.data; // 表格数据
    });
  },
  methods: {
    zt(data) {
      this.sid = data;
      console.log(this.id);
    },
    // checkTime(i){
    //   if(i<10){
    //     i='0'+i
    //     }
    //     return i
    //     },
    cha() {
      this.pagenum = 1;
      this.getUser();
    },
    getUser() {
      console.log(this.$route.query.id);
      this.$axios
        .post(this.$api.cardinfo, {
          cardnum: this.cardinput, // 查询参数
          account: this.input,
          sy_nickname: this.syinput,
          // shop_name: this.shopname,
          shop_id: this.sid.toString(),
          id: this.id,
          nickname: this.nameinput,
          start_time: this.start,
          end_time: this.end,
          page: this.pagenum, // 当前页码
          page_size: this.pagesize, // 每页显示条数
        })
        .then((res) => {
          this.tableData = res.data.list; // 表格数据
          this.total = res.data.count; // 总条目数
          this.lingshou = res.data.total_recharge_money; //总充值金额
        });
    },
    handleSizeChange(val) {
      //改变时
      this.pagesize = val;
      this.getUser();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      //条目改变时
      this.pagenum = val;
      this.getUser();
      console.log(`当前页: ${val}`);
    },
  },
  computed: {},
};
</script>
<style scoped>
.spbiao {
  width: 70%;
  left: 12%;
  min-height: 200px;
  position: fixed;
  background: #fff;
  border: 1px solid #ebeef5;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  word-break: break-all;
  top: 15%;
  padding: 2%;
}

.a {
  text-decoration: none;
}

.el-menu-item {
  font-size: 20px;
}

.el-header {
  position: relative;
  width: 100%;
  height: 60px;
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  display: block;
  position: absolute;
  left: 0;
  top: 70px;
  bottom: 0;
  color: #333;
}
.el-main {
  position: absolute;
  left: 200px;
  right: 0;
  top: 70px;
  bottom: 0;
  overflow-y: scroll;
}
.active {
  color: #409eff !important;
}
</style>
