<template>
  <div class="about">
    <el-container style="height: 500px; border: 1px solid #eee">
      <el-header style="text-align: right; font-size: 12px">
        <tou></tou>
      </el-header>
      <el-container>
        <el-aside width="180px" style="background-color: rgb(238, 241, 246)">
          <leftside></leftside>
        </el-aside>
        <el-main>
          <el-date-picker
            v-model="start"
            type="date"
            placeholder="开始日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            style="width: 100px"
          >
          </el-date-picker>
          至
          <el-date-picker
            v-model="end"
            type="date"
            placeholder="结束日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            style="width: 100px"
          >
          </el-date-picker>
          <label for="" style="margin-left: 20px">id：</label>
          <el-input placeholder="请输入id" v-model="id" style="width: 150px" clearable>
          </el-input>
          <label for="" style="margin-left: 20px">手机号：</label>
          <el-input
            placeholder="请输入内容"
            v-model="input"
            style="width: 170px"
            clearable
          >
          </el-input
          ><br />
          <label for="" style="margin-left: 20px">卡号：</label>
          <el-input
            placeholder="请输入内容"
            v-model="cardinput"
            style="width: 170px; margin-top: 3%"
            clearable
          >
          </el-input>
          <label for="" style="margin-left: 20px">姓名：</label>
          <el-input
            placeholder="请输入内容"
            v-model="nameinput"
            style="width: 170px"
            clearable
          >
          </el-input>
          <label for="" style="margin-left: 20px">店铺名称：</label>
          <!-- <el-input
            placeholder="请输入内容"
            v-model="shopname"
            style="width: 170px"
            clearable
          >
          </el-input> -->
          <el-select v-model="shopname" filterable @change="zt">
            <el-option :name="0" label="全部" :value="0"> </el-option>
            <el-option
              v-for="item in option"
              :key="item.shop_id"
              :name="item.shop_id"
              :label="item.shop_name"
              :value="item.shop_id"
            >
            </el-option>
          </el-select>
          <label for="" style="margin-left: 20px">收银员：</label>
          <el-input
            placeholder="请输入内容"
            v-model="syinput"
            style="width: 170px"
            clearable
          >
          </el-input>
          <el-button type="warning" @click="cha" style="margin-left: 20px"
            >查看</el-button
          >
          <el-button type="warning" @click="handleExcel" style="margin-left: 10px"
            >导出</el-button
          >
          <br /><br />
          <!-- 内容区域 -->
          <Breadcrumb></Breadcrumb>
          <h5>零售汇总：{{ lingshou }}元</h5>
          <h5>退货汇总：{{ tuihuo }} 元</h5>
          <h5>纯利润：{{ lirun }}元</h5>
          <!-- 表格 -->
          <div class="table">
            <el-table :data="tableData" border style="width: 100%">
              <el-table-column fixed prop="order_id" label="ID"> </el-table-column>
              <el-table-column prop="order_number" label="订单编号"> </el-table-column>
              <el-table-column prop="sy_nickname" label="收银员"> </el-table-column>
              <el-table-column prop="nickname" label="用户名"> </el-table-column>
              <el-table-column prop="account" label="电话"> </el-table-column>
              <el-table-column prop="cardnum" label="卡号"> </el-table-column>
              <el-table-column prop="create_time" label="结账时间"> </el-table-column>
              <el-table-column prop="shop_name" label="消费点"> </el-table-column>
              <el-table-column prop="total_price" label="总易货额"> </el-table-column>
              <el-table-column prop="refund_money" label="退货额"> </el-table-column>
              <el-table-column property="total_xj" label="总现金"></el-table-column>
              <el-table-column prop="total_integral" label="总积分"> </el-table-column>
              <el-table-column prop="refund_integral" label="退货积分"> </el-table-column>
              <el-table-column
                label="状态"
                prop="pay_status"
                :formatter="stateFormat"
              ></el-table-column>
              <el-table-column fixed="right" label="操作" width="100">
                <template slot-scope="scope">
                  <el-button @click="handleClick(scope.row)" type="text" size="medium"
                    >查看
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!-- /End 表格 -->
          <div>
            <el-dialog title=" " :visible.sync="visible" width="90%">
              <el-table :data="gridData" height="520">
                <el-table-column property="id" label="编码"></el-table-column>
                <el-table-column property="title" label="名称"></el-table-column>
                <el-table-column property="photo" label="商品">
                  <template slot-scope="scope">
                    <img
                      :src="scope.row.photo"
                      @error="defImg()"
                      width="40"
                      height="40"
                    />
                  </template>
                </el-table-column>
                <el-table-column property="price" label="单价"></el-table-column>
                <el-table-column property="xj" label="现金"></el-table-column>
                <el-table-column property="num" label="数量"></el-table-column>
                <el-table-column property="discount" label="折扣"></el-table-column>
                <el-table-column prop="give_integral" label="赠送积分"> </el-table-column>
                <el-table-column prop="total_give_integral" label="总积分">
                </el-table-column>

                <el-table-column prop="refund_num" label="退货数量"> </el-table-column>
                <el-table-column
                  property="discount_price"
                  label="折后价"
                ></el-table-column>
                <el-table-column
                  property="total_price"
                  label="总易货额"
                ></el-table-column>
                <el-table-column property="total_xj" label="总现金"></el-table-column>
                <el-table-column property="refund_money" label="退货额"></el-table-column>
                <el-table-column prop="refund_integral" label="退货积分">
                </el-table-column>
              </el-table>
            </el-dialog>
          </div>

          <!-- <Pagination></Pagination> -->

          <!-- 分页 -->
          <div class="pagination">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[20, 40, 60]"
              :page-size="pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
          <!-- /End 分页 -->
          <!-- /End 内容区域 -->
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import leftside from "../components/leftside.vue";
import tou from "../components/tou.vue";
// import axios from "axios";
export default {
  name: "dingdan",
  components: {
    leftside,
    tou,
  },
  data() {
    return {
      defaultImg: require("../../static/1.png"),
      start: "",
      end: "",
      input: "",
      cardinput: "",
      nameinput: "",
      gridData: [],
      syinput: "",
      shopname: "",
      myInstance: null, // axios实例
      tableData: [], // 表格数据
      visible: false,
      currentPage: 1, // 当前页码
      total: 0, // 总条目数
      query: "", // 查询参数
      pagenum: 1, // 当前页码
      pagesize: 20, // 每页显示条数
      id: this.$route.query.id,
      lingshou: 0,
      tuihuo: 0,
      lirun: 0,
      option: [],
      sid: "0",
    };
  },
  mounted() {
    this.getUser();
    this.$axios.post(this.$api.shoplist, { type_id:3}).then((res) => {
      this.option = res.data; // 表格数据
    });
  },
  methods: {
    handleExcel() {
      let fields = {
        title0: "ID",
        title1: "订单编号",
        title2: "收银员",
        title3: "用户名",
        title4: "电话",
        title5: "卡号",
        title6: "结账时间",
        title7: "消费点",
        title8: "总易货额",
        title9: "退货额",
        title10: "总现金",
        title11: "总积分",
        title12: "退货积分",
      };
      let arry = [];
      let params = {};
      params.page = 1;
      params.pageSize = 999; //这里也可以直接通过接口返回的total，拿值
      this.$axios
        .post(this.$api.daoorder, {
          // cardnum: this.cardinput, // 查询参数
          cardnum: this.cardinput, // 查询参数
          account: this.input,
          nickname: this.nameinput,
          sy_nickname: this.syinput,
          // shop_name: this.shopname,
          shop_id: this.sid.toString(),
          start_time: this.start,
          end_time: this.end,
        })
        .then((res) => {
          console.log(res);
          res.data.forEach((ele) => {
            arry.push({
              title0: ele.order_id,
              title1: ele.order_number,
              title2: ele.sy_nickname,
              title3: ele.nickname,
              title4: ele.account,
              title5: ele.cardnum,
              title6: ele.create_time,
              title7: ele.shop_name,
              title8: ele.total_price,
              title9: ele.refund_money,
              title10: ele.total_xj,
              title11: ele.total_integral,
              title12: ele.refund_integral,
            });
          });
          console.log(arry);
          console.log(fields);
          this.$xlsx.xlsx(arry, fields, "excel统计列表");
        });
    },
    zt(data) {
      this.sid = data;
      console.log(this.id);
    },
    defImg() {
      let img = event.srcElement;
      img.src = this.defaultImg;
      img.onerror = null; //防止闪图
    },
    stateFormat(row) {
      if (row.pay_status === 1) {
        return "已支付";
      } else {
        return "未支付";
      }
    },
    cha() {
      this.pagenum = 1;
      this.getUser();
    },
    getUser() {
      this.$axios
        .post(this.$api.dingdan, {
          cardnum: this.cardinput, // 查询参数
          account: this.input,
          nickname: this.nameinput,
          id: this.id,
          sy_nickname: this.syinput,
          // shop_name: this.shopname,
          shop_id: this.sid.toString(),
          start_time: this.start,
          end_time: this.end,
          page: this.pagenum, // 当前页码
          page_size: this.pagesize, // 每页显示条数
        })
        .then((res) => {
          this.tableData = res.data.list; // 表格数据
          this.total = res.data.count; // 总条目数
          this.lingshou = res.data.total_income; //零售汇总
          this.tuihuo = res.data.total_refund; //退货汇总
          this.lirun = res.data.total_profit; //利润
        });
    },

    handleClick(row) {
      console.log(row);
      this.$axios
        .post(this.$api.dingdaninfo, {
          order_id: row.order_id,
        })
        .then((res) => {
          this.visible = true;
          this.gridData = res.data; // 表格数据
        });
    },
    handleSizeChange(val) {
      //改变时
      this.pagesize = val;
      this.getUser();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      //条目改变时
      this.pagenum = val;
      this.getUser();
      console.log(`当前页: ${val}`);
    },
  },
  computed: {},
};
</script>
<style scoped>
.spbiao {
  width: 70%;
  left: 12%;
  min-height: 200px;
  position: fixed;
  background: #fff;
  border: 1px solid #ebeef5;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  word-break: break-all;
  top: 15%;
  padding: 2%;
}

.a {
  text-decoration: none;
}

.el-menu-item {
  font-size: 20px;
}

.el-header {
  position: relative;
  width: 100%;
  height: 60px;
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  display: block;
  position: absolute;
  left: 0;
  top: 70px;
  bottom: 0;
  color: #333;
}
.el-main {
  position: absolute;
  left: 200px;
  right: 0;
  top: 70px;
  bottom: 0;
  overflow-y: scroll;
}
.active {
  color: #409eff !important;
}
h5 {
  display: inline-block;
  width: 30%;
}
</style>
