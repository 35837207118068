<template>
  <div class="about">
    <el-container style="height: 500px; border: 1px solid #eee">
      <el-header style="text-align: right; font-size: 12px">
        <tou></tou>
      </el-header>
      <el-container>
        <el-aside width="180px" style="background-color: rgb(238, 241, 246)">
          <leftside></leftside>
        </el-aside>
        <el-main>
          <!-- <el-date-picker
            v-model="start"
            type="date"
            placeholder="开始日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            style="width: 100px"
          >
          </el-date-picker>
          至
          <el-date-picker
            v-model="end"
            type="date"
            placeholder="结束日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            style="width: 100px"
          >
          </el-date-picker> -->
          <label for="" style="margin-left: 20px">电话：</label>
          <el-input
            placeholder="请输入内容"
            v-model="input"
            style="width: 150px"
            clearable
          >
          </el-input>
          <label for="" style="margin-left: 20px">报名人：</label>
          <el-input
            placeholder="请输入内容"
            v-model="bnameinput"
            style="width: 150px"
            clearable
          >
          </el-input>
          <label for="" style="margin-left: 20px">姓名：</label>
          <el-input
            placeholder="请输入内容"
            v-model="nameinput"
            style="width: 150px"
            clearable
          >
          </el-input>
          <el-button type="warning" @click="cha" style="margin-left: 10px"
            >查看</el-button
          >
          <!-- <el-button type="warning" @click="handleExcel" style="margin-left: 10px"
            >导出</el-button
          > -->
          <br /><br />
          <!-- 内容区域 -->
          <Breadcrumb></Breadcrumb>
          <h5>总人数：{{ total }}人</h5>
          <!-- 表格 -->
          <div class="table">
            <el-table :data="tableData" border style="width: 100%">
              <el-table-column fixed prop="id" label="ID"> </el-table-column>
              <el-table-column prop="name" label="姓名"> </el-table-column>
              <el-table-column
                label="性别"
                prop="sex"
                :formatter="stateFormat"
              ></el-table-column>
              <el-table-column prop="mobile" label="电话"> </el-table-column>
              <el-table-column prop="create_time" label="创建时间"> </el-table-column>
              <el-table-column prop="person_num" label="人数"> </el-table-column>
              <el-table-column prop="nickname" label="报名人"> </el-table-column>
            </el-table>
          </div>
          <!-- /End 表格 -->
          <el-dialog title=" " :visible.sync="visible">
            <div style="margin-top: 20px; text-align: left">
              级别:
              <el-select v-model="level" placeholder="级别" @change="ka">
                <el-option
                  v-for="item in option"
                  :key="item.id"
                  :name="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <br />
              <el-button type="success" @click="ok" style="margin: 5%">确定</el-button>
              <el-button :plain="true" @click="no" style="margin: 5%">取消</el-button>
            </div>
          </el-dialog>

          <!-- <Pagination></Pagination> -->

          <!-- 分页 -->
          <div class="pagination">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[20, 40, 60]"
              :page-size="pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
          <!-- /End 分页 -->
          <!-- /End 内容区域 -->
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
// import axios from "axios";
import leftside from "../components/leftside.vue";
import tou from "../components/tou.vue";
export default {
  name: "apply",
  components: {
    leftside,
    tou,
  },
  data() {
    return {
      defaultImg: require("../../static/1.png"),
      start: "",
      end: "",
      input: "",
      cardinput: "",
      bnameinput: "",
      nameinput: "",
      gridData: [],
      myInstance: null, // axios实例
      tableData: [], // 表格数据
      visible: false,
      currentPage: 1, // 当前页码
      total: 0, // 总人数
      query: "", // 查询参数
      pagenum: 1, // 当前页码
      pagesize: 20, // 每页显示条数
      user_id: "",
      level: "",
      level_id: "",
      option: [],
      sexname: "",
    };
  },
  mounted() {
    this.getUser();
  },
  methods: {
    handleExcel() {
      let fields = {
        // title0: "序号",
        title0: "ID",
        title1: "姓名",
        title2: "性别",
        title3: "电话",
        title4: "人数",
        title5: "申请人",
        title6: "申请时间",
      };
      let arry = [];
      // let i = 0;
      let params = {};
      params.page = 1;
      params.pageSize = 999; //这里也可以直接通过接口返回的total，拿值
      this.$axios
        .post(this.$api.daouser, {
          // cardnum: this.cardinput, // 查询参数
          account: this.input,
          nickname: this.nameinput,
          start_time: this.start,
          end_time: this.end,
        })
        .then((res) => {
          console.log(res);
          res.data.forEach((ele) => {
            // i++;
            arry.push({
              // title0: i,
              title0: ele.id,
              title1: ele.name,
              title2: ele.mobile,
              title3: this.sexname,
              title4: ele.person_num,
              title5: ele.nickname,
              title6: ele.create_time,
            });
          });
          console.log(arry);
          console.log(fields);
          this.$xlsx.xlsx(arry, fields, "excel统计列表");
        });
    },
    stateFormat(row) {
      if (row.sex === 1) {
        this.sexname = "男";
        return "男";
      } else if (row.sex === 0) {
        this.sexname = "女";
        return "女";
      }
    },
    defImg() {
      let img = event.srcElement;
      img.src = this.defaultImg;
      img.onerror = null; //防止闪图
    },
    cha() {
      this.pagenum = 1;
      this.getUser();
    },
    getUser() {
      this.$axios
        .post(this.$api.sq, {
          // cardnum: this.cardinput, // 查询参数
          name: this.nameinput,
          mobile: this.input,
          nickname: this.bnameinput,
          page: this.pagenum, // 当前页码
          page_size: this.pagesize, // 每页显示条数
          //   start_time: this.start,
          //   end_time: this.end,
        })
        .then((res) => {
          this.tableData = res.data.list; // 表格数据
          this.total = res.data.total_person; // 总人数
        });
    },

    handleClick(row) {
      console.log(row);
      this.user_id = row.user_id;
      this.$axios
        .post(this.$api.level, {
          // id: row.user_id,
        })
        .then((res) => {
          this.visible = true;
          this.option = res.data; // 表格数据
        });
    },
    ka(data) {
      console.log(data);
      this.level_id = data;
    },
    ok() {
      console.log(this.user_id);
      console.log(this.level_id);
      //确定添加银行卡
      this.$confirm("请您确定修改, 是否继续?", "提示", {
        //弹框确定继续操作
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$axios
            .post(this.$api.setlevel, {
              user_id: this.user_id, //银行卡id
              level_id: this.level_id, //提现金额
            })
            .then((res) => {
              if (res.code == 1) {
                //判断是否结算成功
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                this.visible = false;
                this.getUser();
                this.level = "";
                this.level_id = "";
                // location.reload() //刷新页面
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
          this.visible = false;
        });
    },

    no() {
      //取消添加
      this.$message({
        message: "已取消",
        type: "warning",
      });
      this.visible = false;
      this.level = "";
    },
    handleSizeChange(val) {
      //改变时
      this.pagesize = val;
      this.getUser();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      //条目改变时
      this.pagenum = val;
      this.getUser();
      console.log(`当前页: ${val}`);
    },
  },
  computed: {},
};
</script>
<style scoped>
.spbiao {
  width: 70%;
  left: 12%;
  min-height: 200px;
  position: fixed;
  background: #fff;
  border: 1px solid #ebeef5;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  word-break: break-all;
  top: 15%;
  padding: 2%;
}

.a {
  text-decoration: none;
}

.el-menu-item {
  font-size: 20px;
}

.el-header {
  position: relative;
  width: 100%;
  height: 60px;
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  display: block;
  position: absolute;
  left: 0;
  top: 70px;
  bottom: 0;
  color: #333;
}
.el-main {
  position: absolute;
  left: 200px;
  right: 0;
  top: 70px;
  bottom: 0;
  overflow-y: scroll;
}
.active {
  color: #409eff !important;
}
</style>
