<template>
  <div class="about">
    <el-container style="height: 500px; border: 1px solid #eee">
      <el-header style="text-align: right; font-size: 12px">
        <tou></tou>
      </el-header>
      <el-container>
        <el-aside width="180px" style="background-color: rgb(238, 241, 246)">
          <leftside></leftside>
        </el-aside>
        <el-main>
          <el-date-picker
            v-model="start"
            type="date"
            placeholder="开始日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            style="width: 100px"
          >
          </el-date-picker>
          至
          <el-date-picker
            v-model="end"
            type="date"
            placeholder="结束日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            style="width: 100px"
          >
          </el-date-picker>
          <label for="" style="margin-left: 20px">商品名：</label>
          <el-input
            placeholder="请输入内容"
            v-model="input"
            style="width: 150px"
            clearable
          >
          </el-input>
          <label for="" style="margin-left: 20px">店铺名称：</label>
          <!-- <el-input
            placeholder="请输入内容"
            v-model="shopname"
            style="width: 170px"
            clearable
          >
          </el-input> -->
          <el-select v-model="shopname" filterable @change="zt">
            <el-option :name="0" label="全部" :value="0"> </el-option>
            <el-option
              v-for="item in option"
              :key="item.shop_id"
              :name="item.shop_id"
              :label="item.shop_name"
              :value="item.shop_id"
            >
            </el-option>
          </el-select>
          <!-- <label for="" style="margin-left:20px">姓名：</label>
          <el-input
            placeholder="请输入内容"
            v-model="nameinput"
            style="width:150px"
            clearable
          >
          </el-input> -->
          <el-button type="warning" @click="cha" style="margin-left: 10px"
            >查看</el-button
          >
          <el-button
            type="warning"
            @click="handleExcel"
            style="margin-left: 10px"
            >导出</el-button
          >
          <br /><br />
          <!-- 内容区域 -->
          <Breadcrumb></Breadcrumb>

          <!-- 表格 -->
          <div class="table">
            <el-table
              id="out-table"
              :data="tableData"
              border
              style="width: 100%"
            >
              <el-table-column fixed prop="goods_id" label="ID">
              </el-table-column>
              <el-table-column prop="shop_name" label="商家"> </el-table-column>
              <el-table-column prop="title" label="商品名"> </el-table-column>
              <el-table-column property="photo" label="图片">
                <template slot-scope="scope">
                  <img
                    :src="scope.row.photo"
                    @error="defImg()"
                    width="40"
                    height="40"
                  />
                </template>
              </el-table-column>
              <el-table-column prop="num" label="数量"> </el-table-column>
              <el-table-column prop="guige" label="规格"> </el-table-column>
              <el-table-column prop="give_integral" label="赠送积分">
              </el-table-column>
              <el-table-column prop="price" label="易货额"> </el-table-column>
              <el-table-column prop="xj" label="易货现金"> </el-table-column>
              <el-table-column prop="zk_xj" label="折扣现金"> </el-table-column>
              <el-table-column prop="create_time" label="时间">
              </el-table-column>
              <el-table-column prop="sale_num" label="售出"> </el-table-column>
              <el-table-column prop="nickname" label="入库员">
              </el-table-column>

              <el-table-column fixed="right" label="操作" width="100">
                <template slot-scope="scope">
                  <el-button
                    @click="handleClick(scope.row)"
                    type="text"
                    size="medium"
                    >查看
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div>
            <el-dialog title=" " :visible.sync="visible" width="90%">
              操作类型：
              <el-select v-model="status" placeholder="商品操作" @change="zts">
                <el-option
                  v-for="items in options"
                  :key="items.value"
                  :name="items.value"
                  :label="items.label"
                  :value="items.value"
                >
                </el-option>
              </el-select>
              <el-table :data="gridData" height="520">
                <el-table-column property="id" label="id"></el-table-column>
                <el-table-column
                  property="nickname"
                  label="姓名"
                ></el-table-column>

                <el-table-column prop="ynum" label="更改前"> </el-table-column>
                <el-table-column prop="change_num" label="改变值">
                </el-table-column>
                <el-table-column
                  property="num"
                  label="更改后"
                ></el-table-column>
                <!-- <el-table-column prop="intro" label="备注"> </el-table-column> -->
                <el-table-column
                  label="操作类型"
                  prop="optype"
                  :formatter="stateFormat"
                ></el-table-column>
                <el-table-column prop="ddd" label="时间"> </el-table-column>
              </el-table>
              <div class="pagination">
                <el-pagination
                  @size-change="handleSizeChanges"
                  @current-change="handleCurrentChanges"
                  :current-page="currentPage"
                  :page-sizes="[20, 40, 60]"
                  :page-size="pagesizes"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="totals"
                >
                </el-pagination>
              </div>
            </el-dialog>
          </div>
          <!-- /End 表格 -->

          <!-- <Pagination></Pagination> -->

          <!-- 分页 -->
          <div class="pagination">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[20, 40, 60]"
              :page-size="pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
          <!-- /End 分页 -->
          <!-- /End 内容区域 -->
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
// import axios from "axios";
import leftside from "../components/leftside.vue";
import tou from "../components/tou.vue";
export default {
  name: "shop",
  components: {
    leftside,
    tou,
  },
  data() {
    return {
      defaultImg: require("../../static/1.png"),
      start: "",
      end: "",
      input: "",
      cardinput: "",
      nameinput: "",
      gridData: [],
      myInstance: null, // axios实例
      tableData: [], // 表格数据
      visible: false,
      currentPage: 1, // 当前页码
      total: 0, // 总条目数
      totals: 0, // 总条目数
      query: "", // 查询参数
      pagenum: 1, // 当前页码
      pagesize: 20, // 每页显示条数
      pagenums: 1, // 当前页码
      pagesizes: 20, // 每页显示条数
      option: [],
      options: [
        {
          value: "1",
          label: "添加库存",
        },
        {
          value: "2",
          label: "设置价格",
        },
        {
          value: "3",
          label: "设置现金",
        },
        {
          value: "4",
          label: "客户消费",
        },
      ],
      sid: "0",
      shopname: "",
      status: "", //状态
      statusid: "",
      goodsid: "",
    };
  },
  mounted() {
    this.getUser();
    this.$axios.post(this.$api.shoplist, {}).then((res) => {
      this.option = res.data; // 表格数据
    });
  },
  methods: {
    handleExcel() {
      let fields = {
        title0: "ID",
        title1: "商家",
        title2: "商品名",
        title3: "数量",
        title4: "规格",
        title5: "赠送积分",
        title6: "易货额",
        title7: "易货现金",
        title8: "折扣现金",
        title9: "时间",
        title10: "入库员",
      };
      let arry = [];
      let params = {};
      params.page = 1;
      params.pageSize = 999; //这里也可以直接通过接口返回的total，拿值
      this.$axios
        .post(this.$api.daogoods, {
          title: this.input,
          shop_id: this.sid.toString(),
          start_time: this.start,
          end_time: this.end,
        })
        .then((res) => {
          console.log(res);
          res.data.forEach((ele) => {
            arry.push({
              title0: ele.goods_id,
              title1: ele.shop_name,
              title2: ele.title,
              title3: ele.num,
              title4: ele.guige,
              title5: ele.give_integral,
              title6: ele.price,
              title7: ele.xj,
              title8: ele.zk_xj,
              title9: ele.create_time,
              title10: ele.nickname,
            });
          });
          console.log(arry);
          console.log(fields);
          this.$xlsx.xlsx(arry, fields, "excel统计列表");
        });
    },
    zts(data) {
      this.statusid = data; //操作类型
      console.log(this.statusid);
      this.spcz();
    },
    zt(data) {
      this.sid = data;
      console.log(this.id);
    },
    stateFormat(row) {
      if (row.optype === 1) {
        return "添加库存";
      } else if (row.optype === 2) {
        return "设置价格";
      } else if (row.optype === 3) {
        return "设置现金";
      } else if (row.optype === 4) {
        return "客户消费";
      }
    },
    defImg() {
      let img = event.srcElement;
      img.src = this.defaultImg;
      img.onerror = null; //防止闪图
    },
    cha() {
      this.pagenum = 1;
      this.getUser();
    },
    getUser() {
      this.$axios
        .post(this.$api.shop, {
          // cardnum: this.cardinput, // 查询参数
          title: this.input,
          shop_id: this.sid.toString(),
          start_time: this.start,
          end_time: this.end,
          //   nickname: this.nameinput,
          page: this.pagenum, // 当前页码
          page_size: this.pagesize, // 每页显示条数
        })
        .then((res) => {
          this.tableData = res.data.list; // 表格数据
          this.total = res.data.count; // 总条目数
        });
    },
    spcz() {
      this.$axios
        .post(this.$api.spcz, {
          goods_id: this.goodsid,
          page: this.pagenums, // 当前页码
          page_size: this.pagesizes, // 每页显示条数
          optype: this.statusid, //操作类型
        })
        .then((res) => {
          this.visible = true;
          console.log(res);
          this.gridData = res.data.list; // 表格数据
          this.totals = res.data.count; // 总条目数
        });
    },
    handleClick(row) {
      console.log(row);
      this.goodsid = row.goods_id; //商品ID
      this.spcz();
    },
    handleSizeChange(val) {
      //改变时
      this.pagesize = val;
      this.getUser();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      //条目改变时
      this.pagenum = val;
      this.getUser();
      console.log(`当前页: ${val}`);
    },
    handleSizeChanges(val) {
      //改变时
      this.pagesizes = val;
      this.spcz();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChanges(val) {
      //条目改变时
      this.pagenums = val;
      this.spcz();
      console.log(`当前页: ${val}`);
    },
  },
  computed: {},
};
</script>
<style scoped>
.spbiao {
  width: 70%;
  left: 12%;
  min-height: 200px;
  position: fixed;
  background: #fff;
  border: 1px solid #ebeef5;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  word-break: break-all;
  top: 15%;
  padding: 2%;
}

.a {
  text-decoration: none;
}

.el-menu-item {
  font-size: 20px;
}

.el-header {
  position: relative;
  width: 100%;
  height: 60px;
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  display: block;
  position: absolute;
  left: 0;
  top: 70px;
  bottom: 0;
  color: #333;
}
.el-main {
  position: absolute;
  left: 200px;
  right: 0;
  top: 70px;
  bottom: 0;
  overflow-y: scroll;
}
.active {
  color: #409eff !important;
}
</style>
