<template>
  <div class="about">
    <el-container style="height: 500px; border: 1px solid #eee">
      <el-header style="text-align: right; font-size: 12px">
        <tou></tou>
      </el-header>
      <el-container>
        <el-aside width="180px" style="background-color: rgb(238, 241, 246)">
          <leftside></leftside>
        </el-aside>
        <el-main>
          <div id="myChart" :style="{ width: '100%', height: '600px' }"></div>
          <div id="myCharts" :style="{ width: '100%', height: '600px' }" style="margin-top:100px"></div>

          <!-- /End 分页 -->
          <!-- </el-main> -->
          <!-- /End 内容区域 -->
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
// import axios from "axios";
import leftside from "../components/leftside.vue";
import tou from "../components/tou.vue";
export default {
  name: "echarts",
  components: {
    leftside,
    tou,
  },
  data() {
    return {};
  },
  mounted() {
    this.getUser();
    this.getUsers();
  },
  methods: {
    getUser() {
      this.$axios.post(this.$api.zhexian, {}).then((res) => {
        let myChart = this.$echarts.init(document.getElementById("myChart"));
        // 绘制图表
        myChart.setOption({
          title: {
            text: "本月数据统计",
          },
          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: ["充值数据", "消费数据"],
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          toolbox: {
            feature: {
              saveAsImage: {},
            },
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: res.data.days,
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              name: "充值数据",
              type: "line",
              stack: "Total",
              data: res.data.recharge_data,
            },
            {
              name: "消费数据",
              type: "line",
              stack: "Total",
              data: res.data.consume_data,
            },
          ],
        });
      });
    },
    getUsers() {
      this.$axios
        .post(this.$api.bing, {
          // shop_name: this.cardinput, // 查询参数
          // shop_id: this.id,
          // mobile: this.input,
          // contact: this.nameinput,
          // page: this.pagenum, // 当前页码
          // page_size: this.pagesize, // 每页显示条数
          // start_time: this.start,
          // end_time: this.end,
        })
        .then((res) => {
          this.tableData = res.data.list; // 表格数据
          this.total = res.data.count; // 总条目数
          let myCharts = this.$echarts.init(
            document.getElementById("myCharts")
          );
          // 绘制图表
          myCharts.setOption({
            title: {
              text: "商品分类统计",
              // subtext: "Fake Data",
              left: "center",
            },
            tooltip: {
              trigger: "item",
            },
            legend: {
              orient: "vertical",
              left: "left",
            },
            series: [
              {
                name: "Access From",
                type: "pie",
                radius: "50%",
                data: res.data,
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: "rgba(0, 0, 0, 0.5)",
                  },
                },
              },
            ],
          });
        });
    },
  },
  computed: {},
};
</script>
<style scoped>
.spbiao {
  width: 70%;
  left: 12%;
  min-height: 200px;
  position: fixed;
  background: #fff;
  border: 1px solid #ebeef5;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  word-break: break-all;
  top: 15%;
  padding: 2%;
}

.a {
  text-decoration: none;
}

.el-menu-item {
  font-size: 20px;
}

.el-header {
  position: relative;
  width: 100%;
  height: 60px;
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  display: block;
  position: absolute;
  left: 0;
  top: 70px;
  bottom: 0;
  color: #333;
}
.el-main {
  position: absolute;
  left: 200px;
  right: 0;
  top: 70px;
  bottom: 0;
  background: white;
  overflow-y: scroll;
}
.active {
  color: #409eff !important;
}
</style>
