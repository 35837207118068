<template>
  <div class="about">
    <el-container style="height: 500px; border: 1px solid #eee">
      <el-header style="text-align: right; font-size: 12px">
        <tou></tou>
      </el-header>
      <el-container>
        <el-aside width="180px" style="background-color: rgb(238, 241, 246)">
          <leftside></leftside>
        </el-aside>
        <el-main>
          <el-date-picker
            v-model="start"
            type="date"
            placeholder="开始日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            style="width: 100px"
          >
          </el-date-picker>
          至
          <el-date-picker
            v-model="end"
            type="date"
            placeholder="结束日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            style="width: 100px"
          >
          </el-date-picker>
          <label for="" style="margin-left: 20px">手机号：</label>
          <el-input
            placeholder="请输入内容"
            v-model="input"
            style="width: 120px"
            clearable
          >
          </el-input>
          <label for="" style="margin-left: 20px">卡号：</label>
          <el-input
            placeholder="请输入内容"
            v-model="cardinput"
            style="width: 120px"
            clearable
          >
          </el-input>
          <label for="" style="margin-left: 20px">商店名称：</label>
          <!-- <el-input
            placeholder="请输入内容"
            v-model="shopname"
            style="width: 200px"
            clearable
          >
          </el-input> -->
          <el-select
            v-model="shopname"
            filterable
            @change="zt"
            style="width: 150px"
          >
            <el-option :name="0" label="全部" :value="0"> </el-option>
            <el-option
              v-for="item in option"
              :key="item.shop_id"
              :name="item.shop_id"
              :label="item.shop_name"
              :value="item.shop_id"
            >
            </el-option>
          </el-select>
          <label for="" style="margin-left: 20px">姓名：</label>
          <el-input
            placeholder="请输入内容"
            v-model="nameinput"
            style="width: 120px"
            clearable
          >
          </el-input>
          <el-button type="warning" @click="cha" style="margin-left: 20px"
            >查看</el-button
          >
          <el-button
            type="warning"
            @click="handleExcel"
            style="margin-left: 10px"
            >导出</el-button
          >
          <br /><br />
          <!-- 内容区域 -->
          <Breadcrumb></Breadcrumb>
          <h5>总条数：{{ total }}条</h5>
          <!-- 表格 -->
          <div class="table">
            <el-table :data="tableData" border style="width: 100%">
              <el-table-column type="expand" label="展开更多">
                <template slot-scope="props">
                  <el-form
                    label-position="left"
                    inline
                    class="demo-table-expand"
                  >
                    <!-- <el-form-item label="开卡时间">
                  <span>{{ props.row.ttime }}</span>
                </el-form-item> -->
                    <el-form-item label="服务费">
                      <span>{{ props.row.moneyfee }}</span>
                    </el-form-item>
                    <!-- <el-form-item label="冻结额度">
                  <span>{{ props.row.dongjie }}</span>
                </el-form-item> -->
                    <el-form-item label="需求商品">
                      <span>{{ props.row.getzy }}</span>
                    </el-form-item>
                    <el-form-item label="核价员">
                      <span>{{ props.row.hejiayuan }}</span>
                    </el-form-item>
                    <el-form-item label="易货商品">
                      <span>{{ props.row.yhsp }}</span>
                    </el-form-item>
                    <el-form-item label="业务员">
                      <span>{{ props.row.yw }}</span>
                    </el-form-item>
                  </el-form>
                </template>
              </el-table-column>
              <el-table-column prop="id" label="ID"> </el-table-column>
              <el-table-column prop="nickname" label="用户名">
              </el-table-column>
              <el-table-column prop="account" label="电话"> </el-table-column>
              <el-table-column prop="cardnum" label="卡号"> </el-table-column>
              <el-table-column prop="ttime" label="开卡时间"> </el-table-column>
              <el-table-column prop="shop_name" label="所属网点">
              </el-table-column>
              <el-table-column
                label="卡号类型"
                prop="card_type"
                :formatter="stateFormat"
              ></el-table-column>
              <el-table-column
                label="开卡类型"
                prop="open_type"
                :formatter="stateFormatk"
              ></el-table-column>
              <el-table-column prop="money" label="余额"> </el-table-column>
              <!-- <el-table-column
            prop="moneyfee"
            label="服务费"
            width="100"
            sortable="custom"
          >
          </el-table-column> -->
              <!-- <el-table-column
            label="冻结状态"
            prop="qx"
            :formatter="stateFormats"
          ></el-table-column> -->
              <el-table-column prop="dongjie" label="冻结额度">
              </el-table-column>
              <!-- <el-table-column prop="getzy" label="需求商品"> </el-table-column> -->
              <!-- <el-table-column prop="hejiayuan" label="核价员"> </el-table-column> -->
              <!-- <el-table-column prop="yhsp" label="易货商品"> </el-table-column> -->
              <!-- <el-table-column prop="yw" label="业务员"> </el-table-column> -->
              <el-table-column label="合同" width="150" height="150">
                <template slot-scope="scope">
                  <el-image
                    style="width: 100px; height: 100px"
                    :src="scope.row.hetong_arr[0]"
                    :preview-src-list="scope.row.hetong_arr"
                    @error="defImg()"
                  >
                  </el-image>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="330">
                <template slot-scope="scope">
                  <!-- <el-button @click="handleClick(scope.row)">充值</el-button> -->
                  <el-button @click="handleClick(scope.row)" style="margin: 0"
                    >充值</el-button
                  >
                  <el-button @click="handleClicks(scope.row)"
                    >修改密码</el-button
                  >
                  <el-button @click="handleClickdj(scope.row)" style="margin: 0"
                    >冻结额度</el-button
                  >
                  <router-link
                    :to="{
                      path: '/vipcardinfo',
                      query: { id: scope.row.id },
                    }"
                    class="a"
                  >
                    <el-button>充值记录</el-button>
                  </router-link>
                  <router-link
                    :to="{
                      path: '/dingdan',
                      query: { id: scope.row.id },
                    }"
                    class="a"
                  >
                    <el-button>订单列表</el-button>
                  </router-link>
                  <router-link
                    :to="{
                      path: '/xflist',
                      query: { id: scope.row.id },
                    }"
                    class="a"
                  >
                    <el-button>消费记录</el-button>
                  </router-link>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!-- /End 表格 -->

          <!-- <Pagination></Pagination> -->
          <div>
            <el-popover
              placement="left"
              width="780px"
              trigger="manual"
              :offset="300"
              v-model="visible"
              ><br /><br />
              <div class="spbiao">
                <!-- 收款金额：
            <el-input
              v-model="yingshou"
              placeholder="请输入内容"
              style="width: 300px"
            ></el-input
            ><br />
            <br /> -->
                充值金额：
                <el-input
                  v-model="zhifu"
                  placeholder="请输入内容"
                  style="width: 300px"
                ></el-input
                ><br /><br />
                <span style="color: red">*扣款请输入负值*</span><br /><br />
                充值原因：
                <el-input
                  type="text"
                  placeholder="请输入内容"
                  style="width: 300px"
                  v-model="yuanyin"
                  minlength="10"
                  show-word-limit
                ></el-input
                ><br /><br />
                <!-- <el-select v-model="fangshi" placeholder="充值类型">
              <el-option
                v-for="item in options"
                :key="item.value"
                :name="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select> -->
                <el-button type="success" @click="jiesuan">确定</el-button>
                <el-button :plain="true" @click="nopay">取消</el-button>
              </div>
            </el-popover>
          </div>
          <div>
            <el-popover
              placement="left"
              width="780px"
              trigger="manual"
              :offset="300"
              v-model="visibles"
              ><br /><br />
              <div class="spbiao">
                修改密码：
                <el-input
                  v-model="onepas"
                  placeholder="请输入内容"
                  style="width: 300px"
                  show-password
                ></el-input
                ><br />
                <br />
                确认密码：
                <el-input
                  v-model="twopas"
                  placeholder="请输入内容"
                  style="width: 300px"
                  show-password
                ></el-input
                ><br /><br />
                <el-button type="success" @click="xiugai">确定</el-button>
                <el-button :plain="true" @click="noxiu">取消</el-button>
              </div>
            </el-popover>
          </div>

          <el-popover
            placement="left"
            width="780px"
            trigger="manual"
            :offset="300"
            v-model="visibledj"
            ><br /><br />

            <div class="spbiao">
              冻结额度：
              <el-input
                v-model="djmoney"
                placeholder="请输入内容"
                style="width: 300px"
              ></el-input
              ><br /><br />

              冻结原因：
              <el-input
                type="text"
                placeholder="请输入内容"
                style="width: 300px"
                v-model="reason"
                minlength="10"
                show-word-limit
              ></el-input
              ><br /><br />
              <el-button type="success" @click="dongjie">确定</el-button>
              <el-button :plain="true" @click="noxiu">取消</el-button>
            </div>
          </el-popover>
          <!-- 分页 -->
          <div class="pagination">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[20, 40, 60]"
              :page-size="pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
          <!-- /End 分页 -->
          <!-- /End 内容区域 -->
        </el-main>
      </el-container>
    </el-container>
    <!-- <el-date-picker v-model="start" type="date" placeholder="开始日期">
		</el-date-picker> 至
		<el-date-picker v-model="end" type="date" placeholder="结束日期">
		</el-date-picker>-->
  </div>
</template>

<script>
// import axios from "axios";
import leftside from "../components/leftside.vue";
import tou from "../components/tou.vue";
export default {
  name: "vipcard",
  components: {
    leftside,
    tou,
  },
  data() {
    return {
      defaultImg: require("../../static/1.png"),
      start: "",
      end: "",
      input: "",
      cardinput: "",
      nameinput: "",
      gridData: [],
      shopname: "",
      myInstance: null, // axios实例
      tableData: [], // 表格数据
      visible: false,
      visibles: false,
      currentPage: 1, // 当前页码
      total: 0, // 总条目数
      query: "", // 查询参数
      pagenum: 1, // 当前页码
      pagesize: 20, // 每页显示条数
      yingshou: "",
      zhifu: "",
      yuanyin: "",
      cardid: "",
      option: [],
      id: "0",
      onepas: "", //修改密码
      twopas: "", //确认密码
      visibledj: false, //冻结额度
      hetonglist: [],
      djmoney: "",
      reason: "", //冻结原因
    };
  },
  mounted() {
    this.getUser();
    this.$axios.post(this.$api.shoplist, {}).then((res) => {
      this.option = res.data; // 表格数据
    });
  },
  methods: {
    handleExcel() {
      let fields = {
        title0: "ID",
        title1: "卡号",
        title2: "余额",
        title3: "类型",
        title4: "服务费",
        title5: "时间",
        title6: "电话",
        title7: "姓名",
        title8: "网点",
      };
      let arry = [];
      let params = {};
      let cardtype;
      params.page = 1;
      params.pageSize = 999; //这里也可以直接通过接口返回的total，拿值
      this.$axios
        .post(this.$api.daocard, {
          cardnum: this.cardinput, // 查询参数
          account: this.input,
          nickname: this.nameinput,
          shop_id: this.id.toString(),
          start_time: this.start,
          end_time: this.end,
        })
        .then((res) => {
          console.log(res);
          res.data.forEach((ele) => {
            if (ele.card_type == 3) {
              cardtype = "易货卡";
            } else if (ele.card_type == 5) {
              cardtype = "折扣卡";
            }
            arry.push({
              title0: ele.id,
              title1: ele.cardnum,
              title2: ele.money,
              title3: cardtype,
              title4: ele.moneyfee,
              title5: ele.ttime,
              title6: ele.account,
              title7: ele.nickname,
              title8: ele.shop_name,
            });
          });
          console.log(arry);
          console.log(fields);
          this.$xlsx.xlsx(arry, fields, "excel统计列表");
        });
    },
    zt(data) {
      this.id = data;
      console.log(this.id);
    },
    stateFormat(row) {
      if (row.card_type === 3) {
        return "易货卡";
      } else if (row.card_type === 5) {
        return "折扣卡";
      }
    },
    stateFormatk(row) {
      if (row.open_type === 1) {
        return "客户卡";
      } else if (row.card_type === 2) {
        return "内部卡";
      } else if (row.card_type === 3) {
        return "赠送卡";
      }
    },
    // checkTime(i){
    //   if(i<10){
    //     i='0'+i
    //     }
    //     return i
    //     },
    cha() {
      this.pagenum = 1;
      this.getUser();
    },
    getUser() {
      this.$axios
        .post(this.$api.vipcard, {
          cardnum: this.cardinput, // 查询参数
          account: this.input,
          nickname: this.nameinput,
          // shop_name: this.shopname,
          shop_id: this.id.toString(),
          page: this.pagenum, // 当前页码
          page_size: this.pagesize, // 每页显示条数
          start_time: this.start,
          end_time: this.end,
        })
        .then((res) => {
          var list = res.data.list;
          if (list.length > 0) {
            for (var i = 0; i < list.length; i++) {
              var hetongarr = [];
              if (list[i].hetong != " ") {
                hetongarr = list[i].hetong.split(",");
                for (var k = 0; k < hetongarr.length; k++) {
                  hetongarr[k] =
                    "https://www.zhilongyihuo.com/attachs/" + hetongarr[k];
                }
              }
              list[i].hetong_arr = hetongarr;
            }
          }
          this.tableData = list; // 表格数据
          this.total = res.data.count; // 总条目数
        });
    },

    handleClick(row) {
      console.log(row.id);
      this.visible = true;
      this.cardid = row.id;
    },
    handleClicks(row) {
      console.log(row.id);
      this.visibles = true;
      this.cardid = row.id;
    },
    handleClickdj(row) {
      console.log(row.id);
      this.visibledj = true;
      this.cardid = row.id;
    },
    handleSizeChange(val) {
      //改变时
      this.pagesize = val;
      this.getUser();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      //条目改变时
      this.pagenum = val;
      this.getUser();
      console.log(`当前页: ${val}`);
    },
    xiugai() {
      //最终商品结算
      this.$confirm("请您确定修改密码, 是否继续?", "提示", {
        //弹框确定继续操作
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$axios
            .post(this.$api.xiupwd, {
              id: this.cardid,
              password: this.onepas,
              confirm_password: this.twopas,
            })
            .then((res) => {
              if (res.code == 1) {
                //判断是否结算成功
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                this.visibles = false;
                this.getUser();
                // location.reload() //刷新页面
              } else {
                this.$message({
                  message: res.msg,
                  type: "warning",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
          this.visibles = false;
        });
    },
    dongjie() {
      this.$confirm("请您确定冻结, 是否继续?", "提示", {
        //弹框确定继续操作
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$axios
            .post(this.$api.dongjie, {
              id: this.cardid,
              dongjie: this.djmoney,
              reason: this.reason,
            })
            .then((res) => {
              if (res.code == 1) {
                //判断是否结算成功
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                this.visibledj = false;
                this.getUser();
                this.djmoney = "";
                this.reason = "";
                // location.reload(); //刷新页面
              } else {
                this.$message({
                  message: res.msg,
                  type: "warning",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
          this.visibledj = false;
        });
    },
    noxiu() {
      //取消结算
      this.$message({
        message: "取消修改",
        type: "warning",
      });
      this.visibles = false;
      this.visiblen = false;
      this.visiblefw = false;
      this.visibledj = false;
    },
    jiesuan() {
      //最终商品结算
      this.$confirm("请您确定, 是否继续?", "提示", {
        //弹框确定继续操作
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$axios
            .post(this.$api.add, {
              id: this.cardid,
              recharge_reason: this.yuanyin,
              recharge_money: this.zhifu,
            })
            .then((res) => {
              if (res.code == 1) {
                //判断是否结算成功
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                this.visible = false;
                // location.reload(); //刷新页面
              } else {
                this.$message({
                  message: res.msg,
                  type: "warning",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
          this.visible = false;
        });
    },
    nopay() {
      //取消结算
      this.$message({
        message: "取消结算",
        type: "warning",
      });
      this.visible = false;
    },
    defImg() {
      let img = event.srcElement;
      img.src = this.defaultImg;
      img.onerror = null; //防止闪图
    },
  },
  computed: {},
};
</script>
<style scoped>
.spbiao {
  width: 40%;
  left: 22%;
  min-height: 200px;
  position: fixed;
  background: #fff;
  border: 1px solid #ebeef5;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  word-break: break-all;
  top: 15%;
  padding: 5%;
}

.a {
  text-decoration: none;
}

.el-menu-item {
  font-size: 20px;
}

.el-header {
  position: relative;
  width: 100%;
  height: 60px;
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  display: block;
  position: absolute;
  left: 0;
  top: 70px;
  bottom: 0;
  color: #333;
}
.el-main {
  position: absolute;
  left: 200px;
  right: 0;
  top: 70px;
  bottom: 0;
  overflow-y: scroll;
}
.active {
  color: #409eff !important;
}
.el-card {
  border: none;
}
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>
