<template>
  <div class="con" v-show="aa == 1">
    <h2>置隆总系统欢迎你&nbsp; <i class="el-icon-mouse"></i></h2>
    <br /><br />
    <i class="el-icon-user-solid"></i>&nbsp; <label> 工号:</label>
    <el-input
      placeholder="请输入内容"
      v-model="name"
      clearable
      style="width: 450px"
    >
    </el-input
    ><br /><br /><br />
    <i class="el-icon-view"></i>&nbsp; <label> 密码:</label>
    <el-input
      placeholder="请输入密码"
      v-model="pwd"
      show-password
      style="width: 450px"
    ></el-input
    ><br /><br /><br />
    <el-button type="success" @click="login" class="logn">登录</el-button>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      name: "",
      pwd: "",
      aa: 1,
    };
  },
  mounted() {
    var ua = window.navigator.userAgent.toLowerCase();
    // ua.indexOf('micromessenger')为真-微信端，如果为假，就是其他浏览器
    if (ua.indexOf("micromessenger") > -1) {
      this.aa = 2;
      this.$message({
        message: "请用浏览器打开",
        type: "warning",
      });
    } else {
      this.aa = 1;
      console.log("浏览器");
    }
  },
  methods: {
    login() {
      this.$axios
        .post(this.$api.login, {
          username: this.name,
          password: this.pwd,
        })
        .then((res) => {
          if (res.code == 1) {
            localStorage.setItem("token", res.data.token);
            this.$router.replace({
              name: "echarts",
            });
          }
        });
    },
  },
  computed: {},
};
</script>
<style scoped>
.con {
  width: 80%;
  margin: 15% auto;
}

.logn {
  width: 150px;
  height: 50px;
  font-size: 26px;
}

label {
  margin-right: 20px;
}
</style>
