import axios from 'axios';
import { Message, MessageBox, Loading } from 'element-ui';
import store from '@/store';
import md5 from 'js-md5';

const options = {
  crossDomain: true,
  headers: {}
};
axios.defaults.withCredentials = true;

const xhr = {
  get(url, params) {
    params["timestamp"] =  Date.parse(new Date()).toString().substr(0, 10);
    var res = Object.keys(params).sort();
    var a=[]
    for (var key in res) {
      console.log("key: " + res[key] + " ,value: " + params[res[key]]);
      if(params[res[key]]!=""&&params[res[key]]!=undefined){
        a.push(res[key] + "=" + params[res[key]])
      }
    }
    var sign=md5(a.join('&')+"&secret=XGMsCPW72zTH1vFgNmUKGpUc").toUpperCase()
    axios.defaults.headers.common['sign'] = sign
    axios.defaults.headers.common['token'] = localStorage.getItem("token")
    let loading = Loading.service({
      fullscreen: true,
      text: '拼命加载中...',
      background: 'rgba(0, 0, 0, 0.7)'
    });
    return new Promise((resolve, reject) => {
      axios.create(options).get(url, { params }).then(res => {
        loading.close();
        if (res.data.code !== 1) {
          switch (res.data.code) {
            case 0:
              Message({
                message: res.data.msg,
                type: 'error',
                duration: 2 * 1000
              });
              break;
            default:
              reject(res.data);
          }
        } else {
          resolve(res.data);
        }

      }).catch(err => {
        loading.close();
        switch (err.response.status) {
          case 401:
            Message({
              message: '身份验证不通过',
              type: 'error',
              duration: 2 * 1000
            });
            alert("身份验证不通过即将跳转到首页")
            window.location.href = window.location.origin + "/login"
            break;
          case 500:
            Message({
              message: '服务器错误',
              type: 'error',
              duration: 2 * 1000
            });
            // alert("服务器错误即将跳转到首页")
            // window.location.href="http://shouyin1.xuanfengxia.com/login"
            break;
          default:
            reject(err);
            // alert("即将跳转到首页")
            // window.location.href="http://shouyin1.xuanfengxia.com/login"
            break;
        }

      });
    });
  },
  post(url, params) {
    console.log(params)
    params["timestamp"] =  Date.parse(new Date()).toString().substr(0, 10);
    var res = Object.keys(params).sort();
    var a=[]
    for (var key in res) {
      console.log( params[res[key]]);
      if(params[res[key]]!=""&&params[res[key]]!=undefined){
        a.push(res[key] + "=" + params[res[key]])
      }
     
    }
    console.log(a)
    var sign=md5(a.join('&')+"&secret=XGMsCPW72zTH1vFgNmUKGpUc").toUpperCase()
    axios.defaults.headers.common['sign'] = sign
    axios.defaults.headers.common['token'] = localStorage.getItem("token")
    let loading = Loading.service({
      fullscreen: true,
      text: '拼命加载中...',
      background: 'rgba(0, 0, 0, 0.7)'
    });

    return new Promise((resolve, reject) => {
      axios.create(options).post(url, params).then(res => {
        loading.close();
        if (res.data.code == 1) {
          resolve(res.data);
        } else {
          switch (res.data.code) {
            case 0:
              Message({
                message: res.data.msg,
                type: 'error',
                duration: 2 * 1000
              });
              break;
            default:
              reject(res.data);
          }
          reject(res.data);
        }
      }).catch(err => {
        loading.close();
        console.log(err.response.status)
        switch (err.response.status) {
          case 401:
            Message({
              message: '身份验证不通过',
              type: 'error',
              duration: 2 * 1000
            });
            alert("身份验证不通过即将跳转到首页")
            window.location.href = window.location.origin + "/login"
            break;
          case 500:
            Message({
              message: '服务器错误',
              type: 'error',
              duration: 2 * 1000
            });
            // alert("服务器错误即将跳转到首页")
            // window.location.href="http://shouyin1.xuanfengxia.com/login"
            break;

          default:
            reject(err);
          // alert("即将跳转到首页")
          // window.location.href="http://shouyin1.xuanfengxia.com/login"
        }
      });
    });
  },
  upload(url, params) {
    return new Promise((resolve, reject) => {
      axios.create(options).post(url, params).then(res => {
        if (res.data.code !== 1) {
          switch (res.data.code) {
            case 0:
              Message({
                message: res.data.msg,
                type: 'error',
                duration: 2 * 1000
              });
              resolve(res.data);
              break;
            default:
              reject(res.data);
          }
        } else {
          resolve(res.data);
        }

      }).catch(err => {
        switch (err.response.status) {
          case 401:
            MessageBox.alert(err.response.data.msg, {
              confirmButtonText: '退出',
              callback: () => {
                store.dispatch('user/resetToken').then(() => {
                  location.reload();
                });
              }
            });
            break;
          case 500:
            Message({
              message: '服务器错误',
              type: 'error',
              duration: 2 * 1000
            });
            break;

          default:
            reject(err);
        }
      });
    });
  }
};

export default xhr;
