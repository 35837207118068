<template>
  <div class="con">
    <!-- <el-container style="height: 500px; border: 1px solid #eee">
			<el-aside width="200px" style="background-color: rgb(238, 241, 246)"> -->
    <el-menu :default-openeds="['1']">
      <el-submenu index="1">
        <!-- <template slot="title"><i class="el-icon-message"></i>导航一</template> -->
        <el-menu-item-group>
          <!-- <template slot="title">分组一</template> -->
            <router-link to="echarts" class="a">
            <el-menu-item index="1-1" :class="{ active: '/echarts' === $route.path }"
              >统计图表</el-menu-item
            >
          </router-link>
          <router-link to="store" class="a">
            <el-menu-item index="1-2" :class="{ active: '/store' === $route.path }"
              >商家列表</el-menu-item
            >
          </router-link>
          <router-link to="orderinfo" class="a">
            <el-menu-item index="1-3" :class="{ active: '/orderinfo' === $route.path }"
              >用户列表</el-menu-item
            >
          </router-link>
        </el-menu-item-group>
        <!-- <el-menu-item-group title="分组2"> -->
        <router-link to="vipcard" class="a">
          <el-menu-item index="1-4" :class="{ active: '/vipcard' === $route.path }"
            >卡号列表</el-menu-item
          >
        </router-link>
        <router-link to="vipcardinfo" class="a">
          <el-menu-item index="1-5" :class="{ active: '/vipcardinfo' === $route.path }"
            >额度记录</el-menu-item
          >
        </router-link>
         <router-link to="feelist" class="a">
          <el-menu-item index="1-6" :class="{ active: '/feelist' === $route.path }"
            >服务费记录</el-menu-item
          >
        </router-link>
        <router-link to="dingdan" class="a">
          <el-menu-item index="1-7" :class="{ active: '/dingdan' === $route.path }"
            >订单列表</el-menu-item
          >
        </router-link>
          <router-link to="online" class="a">
          <el-menu-item index="1-8" :class="{ active: '/online' === $route.path }"
            >线上订单</el-menu-item
          >
        </router-link>
        <router-link to="shop" class="a">
          <el-menu-item index="1-9" :class="{ active: '/shop' === $route.path }"
            >商品列表</el-menu-item
          >
        </router-link>
        <router-link to="xflist" class="a">
          <el-menu-item index="2-0" :class="{ active: '/xflist' === $route.path }"
            >消费记录</el-menu-item
          >
        </router-link>
        <router-link to="dcash" class="a">
          <el-menu-item index="2-1" :class="{ active: '/dcash' === $route.path }"
            >提现记录</el-menu-item
          >
        </router-link>
        <router-link to="level" class="a">
          <el-menu-item index="2-2" :class="{ active: '/level' === $route.path }"
            >等级列表</el-menu-item
          >
        </router-link>
         <router-link to="db" class="a">
          <el-menu-item index="2-3" :class="{ active: '/db' === $route.path }"
            >调拨对账</el-menu-item
          >
        </router-link>
         <router-link to="apply" class="a">
          <el-menu-item index="2-4" :class="{ active: '/apply' === $route.path }"
            >易货师申请</el-menu-item
          >
        </router-link>
           <router-link to="storexflist" class="a">
          <el-menu-item index="2-5" :class="{ active: '/storexflist' === $route.path }"
            >商家消费</el-menu-item
          >
        </router-link>
          <router-link to="ydaddinfo" class="a">
          <el-menu-item index="2-6" :class="{ active: '/ydaddinfo' === $route.path }"
            >充值异店记录</el-menu-item
          >
        </router-link>
        <!-- <a href="https://www.zhilongyihuo.com/mcenter/member/card/cid/158.html"
          ><el-menu-item index="2-3">开卡</el-menu-item></a
        > -->
        <!-- </el-menu-item-group> -->
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "leftside",
  data() {
    const item = {
      date: "2016-05-02",
      name: "王小虎",
      address: "上海市普陀区金沙江路 1518 弄",
    };
    return {
      tableData: Array(20).fill(item),
      name: "",
      pwd: "",
      url: "http://shouyin.xuanfengxia.com",
    };
  },
  mounted() {
    console.log(this.$route.path);
  },
  methods: {},
  computed: {},
};
</script>
<style scoped>
.a {
  text-decoration: none;
}

.el-menu-item {
  font-size: 20px;
}

.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  color: #333;
}
.active {
  color: #409eff !important;
}
</style>
