<template>
  <div class="about">
    <el-container style="height: 500px; border: 1px solid #eee">
      <el-header style="text-align: right; font-size: 12px">
        <tou></tou>
      </el-header>
      <el-container>
        <el-aside width="180px" style="background-color: rgb(238, 241, 246)">
          <leftside></leftside>
        </el-aside>
        <el-main>
          <!-- <el-date-picker
            v-model="start"
            type="date"
            placeholder="开始日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            style="width: 100px"
          >
          </el-date-picker>
          至
          <el-date-picker
            v-model="end"
            type="date"
            placeholder="结束日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            style="width: 100px"
          >
          </el-date-picker> -->
          <label for="" style="margin-left: 20px">级别名称：</label>
          <el-input
            placeholder="请输入内容"
            v-model="nameinput"
            style="width: 170px"
            clearable
          >
          </el-input>
          <el-button type="warning" @click="cha" style="margin-left: 20px"
            >查看</el-button
          >
          <el-button type="warning" @click="add" style="margin-left: 20px"
            >添加</el-button
          >
          <br /><br />
          <!-- 内容区域 -->
          <Breadcrumb></Breadcrumb>

          <!-- 表格 -->
          <div class="table">
            <el-table :data="tableData" border style="width: 100%">
              <el-table-column fixed prop="id" label="ID"> </el-table-column>
              <el-table-column fixed prop="name" label="级别名"> </el-table-column>
              <el-table-column prop="percentage" label="提成比例"> </el-table-column>
              <el-table-column prop="create_time" label="创建时间"> </el-table-column>
              <el-table-column fixed="right" label="操作" width="100">
                <template slot-scope="scope">
                  <el-button @click="handleClickn(scope.row)" type="text" size="medium"
                    >编辑
                  </el-button>
                  <el-button @click="del(scope)" type="text" size="medium"
                    >删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div>
            <el-popover
              placement="left"
              width="780px"
              trigger="manual"
              :offset="300"
              v-model="visibles"
              ><br /><br />
              <div class="spbiao">
                级别名称：
                <el-input
                  v-model="dengji"
                  placeholder="请输入级别名称"
                  style="width: 300px"
                ></el-input
                ><br />
                <br />
                提成比例：
                <el-input
                  v-model="bili"
                  placeholder="请输入提成比例"
                  style="width: 300px"
                ></el-input
                >(%)<br /><br />
                <el-button type="success" @click="ok">确定</el-button>
                <el-button :plain="true" @click="no">取消</el-button>
              </div>
            </el-popover>
          </div>
          <div>
            <el-popover
              placement="left"
              width="780px"
              trigger="manual"
              :offset="300"
              v-model="visible"
              ><br /><br />
              <div class="spbiao">
                级别名称：
                <el-input
                  v-model="dengji"
                  placeholder="请输入级别名称"
                  style="width: 300px"
                ></el-input
                ><br />
                <br />
                提成比例：
                <el-input
                  v-model="bili"
                  placeholder="请输入提成比例"
                  style="width: 300px"
                ></el-input
                >(%)<br /><br />
                <el-button type="success" @click="xiu">确定</el-button>
                <el-button :plain="true" @click="noxiu">取消</el-button>
              </div>
            </el-popover>
          </div>
          <!-- 分页 -->
          <div class="pagination">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[20, 40, 60]"
              :page-size="pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
          <!-- /End 分页 -->
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import leftside from "../components/leftside.vue";
import tou from "../components/tou.vue";
// import axios from "axios";
export default {
  name: "level",
  components: {
    leftside,
    tou,
  },
  data() {
    return {
      start: "",
      end: "",
      nameinput: "",
      gridData: [],
      syinput: "",
      dengji: "",
      bili: "",
      yuanyinid: "",
      id: this.$route.query.id,
      shopname: "",
      myInstance: null, // axios实例
      tableData: [], // 表格数据
      visibles: false, //添加等级
      visible: false, //修改等级
      currentPage: 1, // 当前页码
      total: 0, // 总条目数
      query: "", // 查询参数
      pagenum: 1, // 当前页码
      pagesize: 20, // 每页显示条数
    };
  },
  mounted() {
    this.getUser();
  },
  methods: {
    cha() {
      this.pagenum = 1;
      this.getUser();
    },
    getUser() {
      console.log(this.$route.query.id);
      this.$axios
        .post(this.$api.dengji, {
          page: this.pagenum, // 当前页码
          page_size: this.pagesize, // 每页显示条数
          name: this.nameinput,
          // start_time: this.start,
          // end_time: this.end,
        })
        .then((res) => {
          this.tableData = res.data.list; // 表格数据
          this.total = res.data.count; // 总条目数
        });
    },
    add() {
      this.visibles = true;
    },
    ok() {
      //确定添加银行卡
      this.$confirm("请您确定添加等级, 是否继续?", "提示", {
        //弹框确定继续操作
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$axios
            .post(this.$api.addlevel, {
              name: this.dengji,
              percentage: this.bili,
            })
            .then((res) => {
              if (res.code == 1) {
                //判断是否结算成功
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                this.visibles = false;
                this.getUser();
                // location.reload() //刷新页面
              } else {
                this.$message({
                  message: res.msg,
                  type: "warning",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
          this.visibles = false;
        });
    },

    no() {
      //取消添加
      this.$message({
        message: "取消添加",
        type: "warning",
      });
      this.visibles = false;
    },
    del(strs) {
      //删除某行
      console.log(strs.$index);
      console.log(strs.row.id);
      this.$confirm("请您确定删除, 是否继续?", "提示", {
        //弹框确定继续操作
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$axios
            .post(this.$api.dellevel, {
              id: strs.row.id,
            })
            .then((res) => {
              if (res.code == 1) {
                this.tableData.splice(strs.$index, 1);
                this.$message({
                  message: res.msg,
                  type: "success",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleClickn(row) {
      this.visible = true;
      this.yuanyinid = row.id;
      console.log(row);
    },
    xiu() {
      //确定添加银行卡
      this.$confirm("请您确定, 是否继续?", "提示", {
        //弹框确定继续操作
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$axios
            .post(this.$api.xiulevel, {
              name: this.dengji,
              percentage: this.bili,
              id: this.yuanyinid,
            })
            .then((res) => {
              if (res.code == 1) {
                //判断是否结算成功
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                this.visible = false;
                this.getUser();
                // location.reload() //刷新页面
              } else {
                this.$message({
                  message: res.msg,
                  type: "warning",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消修改",
          });
          this.visible = false;
        });
    },

    noxiu() {
      //取消添加
      this.$message({
        message: "取消修改",
        type: "warning",
      });
      this.visible = false;
    },
    handleSizeChange(val) {
      //改变时
      this.pagesize = val;
      this.getUser();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      //条目改变时
      this.pagenum = val;
      this.getUser();
      console.log(`当前页: ${val}`);
    },
  },
  computed: {},
};
</script>
<style scoped>
.spbiao {
  width: 50%;
  left: 22%;
  min-height: 200px;
  position: fixed;
  background: #fff;
  border: 1px solid #ebeef5;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  word-break: break-all;
  top: 15%;
  padding: 2%;
}

.a {
  text-decoration: none;
}

.el-menu-item {
  font-size: 20px;
}

.el-header {
  position: relative;
  width: 100%;
  height: 60px;
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  display: block;
  position: absolute;
  left: 0;
  top: 70px;
  bottom: 0;
  color: #333;
}
.el-main {
  position: absolute;
  left: 200px;
  right: 0;
  top: 70px;
  bottom: 0;
  overflow-y: scroll;
}
.active {
  color: #409eff !important;
}
</style>
