<template>
  <div class="about">
    <el-container style="height: 500px; border: 1px solid #eee">
      <el-header style="text-align: right; font-size: 12px">
        <tou></tou>
      </el-header>
      <el-container>
        <el-aside width="180px" style="background-color: rgb(238, 241, 246)">
          <leftside></leftside>
        </el-aside>
        <el-main>
          <el-date-picker
            v-model="start"
            type="date"
            placeholder="开始日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            style="width: 100px"
          >
          </el-date-picker>
          至
          <el-date-picker
            v-model="end"
            type="date"
            placeholder="结束日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            style="width: 100px"
          >
          </el-date-picker>
          <label for="">id：</label>
          <el-input placeholder="请输入id" v-model="id" style="width: 120px" clearable>
          </el-input>
          <label for="" style="margin-left: 10px">用户账号：</label>
          <el-input
            placeholder="请输入内容"
            v-model="account"
            style="width: 120px"
            clearable
          >
          </el-input>
          <label for="" style="margin-left: 10px">店铺名称：</label>
          <!-- <el-input
            placeholder="请输入内容"
            v-model="shop_name"
            style="width: 120px"
            clearable
          >
          </el-input> -->
          <el-select v-model="shop_name" filterable @change="zt">
            <el-option :name="0" label="全部" :value="0"> </el-option>
            <el-option
              v-for="item in option"
              :key="item.shop_id"
              :name="item.shop_id"
              :label="item.shop_name"
              :value="item.shop_id"
            >
            </el-option>
          </el-select>
          <label for="" style="margin-left: 10px">商品名称：</label>
          <el-input
            placeholder="请输入内容"
            v-model="title"
            style="width: 120px"
            clearable
          >
          </el-input>
          <label for="" style="margin-left: 10px"> 用户姓名：</label>
          <el-input
            placeholder="请输入内容"
            v-model="nickname"
            style="width: 120px"
            clearable
          >
          </el-input>
          <el-button type="warning" @click="cha" style="margin-left: 10px"
            >查看</el-button
          >
          <br /><br />
          <!-- 内容区域 -->
          <Breadcrumb></Breadcrumb>

          <!-- 表格 -->
          <div class="table">
            <el-table :data="tableData" border style="width: 100%">
              <el-table-column property="id" label="编码"></el-table-column>
              <el-table-column property="shop_name" label="商户名称"></el-table-column>
              <!-- <el-table-column property="logo" label="商家图片">
                  <template slot-scope="scope">
                    <img :src="scope.row.photo" width="40" height="40" />
                  </template>
                </el-table-column> -->
              <el-table-column property="nickname" label="用户名"></el-table-column>
              <el-table-column property="account" label="用户账号"></el-table-column>
              <el-table-column property="cardnum" label="卡号"></el-table-column>
              <el-table-column property="title" label="商品名"></el-table-column>
              <el-table-column property="photo" label="商品">
                <template slot-scope="scope">
                  <img :src="scope.row.photo" @error="defImg()" width="40" height="40" />
                </template>
              </el-table-column>
              <el-table-column property="num" label="数量"></el-table-column>
              <el-table-column property="price" label="单价"></el-table-column>
              <el-table-column
                property="give_integral"
                label="赠送积分"
              ></el-table-column>

              <el-table-column property="xj" label="现金"></el-table-column>
              <el-table-column property="discount" label="折扣"></el-table-column>
              <el-table-column property="discount_price" label="折后价"></el-table-column>
              <el-table-column property="refund_num" label="退货数"></el-table-column>
              <el-table-column property="refund_money" label="退货额"></el-table-column>
              <el-table-column
                property="refund_integral"
                label="退货积分"
              ></el-table-column>
              <el-table-column property="total_price" label="总易货额"></el-table-column>
              <el-table-column property="total_xj" label="总现金"></el-table-column>
              <el-table-column
                property="total_give_integral"
                label="总积分"
              ></el-table-column>
              <el-table-column property="create_time" label="创建时间"></el-table-column>
              <!-- <el-table-column fixed="right" label="操作" width="100">
                  <template slot-scope="scope">
                    <el-button
                      @click="handleClick(scope.row)"
                      type="text"
                      size="medium"
                      >查看
                    </el-button>
                  </template>
                </el-table-column> -->
            </el-table>
          </div>
          <!-- /End 表格 -->
          <div>
            <el-dialog title=" " :visible.sync="visible">
              <el-table :data="gridData" height="520">
                <el-table-column property="shop_id" label="编码"></el-table-column>
                <el-table-column property="shop_name" label="商户名称"></el-table-column>
                <el-table-column property="photo" label="商品">
                  <template slot-scope="scope">
                    <img :src="scope.row.photo" width="40" height="40" />
                  </template>
                </el-table-column>
                <el-table-column property="mobile" label="电话"></el-table-column>
                <el-table-column property="contact" label="负责人"></el-table-column>
                <el-table-column property="addr" label="地址"></el-table-column>
                <el-table-column
                  property="create_time"
                  label="创建时间"
                ></el-table-column>
              </el-table>
            </el-dialog>
          </div>

          <!-- <Pagination></Pagination> -->

          <!-- 分页 -->
          <div class="pagination">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[20, 40, 60]"
              :page-size="pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
          <!-- /End 分页 -->
        </el-main>
        <!-- /End 内容区域 -->
      </el-container>
    </el-container>
  </div>
</template>

<script>
// import axios from "axios";
import leftside from "../components/leftside.vue";
import tou from "../components/tou.vue";
export default {
  name: "xflist",
  components: {
    leftside,
    tou,
  },
  data() {
    return {
      defaultImg: require("../../static/1.png"),
      start: "",
      end: "",
      account: "",
      nickname: "",
      shop_name: "",
      title: "",
      id: this.$route.query.id,
      gridData: [],
      myInstance: null, // axios实例
      tableData: [], // 表格数据
      visible: false,
      currentPage: 1, // 当前页码
      total: 0, // 总条目数
      query: "", // 查询参数
      pagenum: 1, // 当前页码
      pagesize: 20, // 每页显示条数
      option: [],
      sid: "0",
    };
  },
  mounted() {
    this.getUser();
    this.$axios.post(this.$api.shoplist, {}).then((res) => {
      this.option = res.data; // 表格数据
    });
  },
  methods: {
    zt(data) {
      this.sid = data;
      console.log(this.id);
    },
    defImg() {
      let img = event.srcElement;
      img.src = this.defaultImg;
      img.onerror = null; //防止闪图
    },
    // checkTime(i){
    //   if(i<10){
    //     i='0'+i
    //     }
    //     return i
    //     },
    cha() {
      this.pagenum = 1;
      this.getUser();
    },
    getUser() {
      this.$axios
        .post(this.$api.xflist, {
          // shop_name: this.shop_name, // 查询参数
          title: this.title,
          account: this.account,
          id: this.id,
          shop_id: this.sid.toString(),
          nickname: this.nickname,
          page: this.pagenum, // 当前页码
          page_size: this.pagesize, // 每页显示条数
          start_time: this.start,
          end_time: this.end,
        })
        .then((res) => {
          this.tableData = res.data.list; // 表格数据
          this.total = res.data.count; // 总条目数
        });
    },

    handleClick(row) {
      this.visible = true;
      console.log(row);
      this.$axios
        .post(this.$api.handleClick, {
          order_id: row.shop_id,
        })
        .then((res) => {
          this.visible = true;
          this.gridData = res.data; // 表格数据
        });
    },
    handleSizeChange(val) {
      //改变时
      this.pagesize = val;
      this.getUser();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      //条目改变时
      this.pagenum = val;
      this.getUser();
      console.log(`当前页: ${val}`);
    },
  },
  computed: {},
};
</script>
<style scoped>
.spbiao {
  width: 70%;
  left: 12%;
  min-height: 200px;
  position: fixed;
  background: #fff;
  border: 1px solid #ebeef5;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  word-break: break-all;
  top: 15%;
  padding: 2%;
}

.a {
  text-decoration: none;
}

.el-menu-item {
  font-size: 20px;
}

.active {
  color: #409eff !important;
}
.el-header {
  position: relative;
  width: 100%;
  height: 60px;
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  display: block;
  position: absolute;
  left: 0;
  top: 70px;
  bottom: 0;
  color: #333;
}
.el-main {
  position: absolute;
  left: 200px;
  right: 0;
  top: 70px;
  bottom: 0;
  overflow-y: scroll;
}
</style>
