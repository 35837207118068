import Vue from 'vue'
import Router from 'vue-router'//零售收银
import store from './views/store.vue'//商家列表
import storexflist from './views/storexflist.vue'//商家消費列表
import ElementUI from 'element-ui'
import login from './views/login.vue'//登录
import vipcard from './views/vipcard.vue'//会员卡列表
import echarts from './views/echarts.vue'//统计图页面
import vipcardinfo from './views/vipcardinfo.vue'//会员卡充值列表
import dingdan from './views/dingdan.vue'//订单列表
import shop from './views/shop.vue'//商品列表
import xflist from './views/xflist.vue'//消费记录
import dcash from './views/dcash.vue'//提现记录
import level from './views/level.vue'//用户等级列表
import feelist from './views/feelist.vue'//服务费充值记录
import db from './views/db.vue'//对账调拨记录
import apply from './views/apply.vue'//易货师申请表
import online from './views/online.vue'//线上订单
import ydaddinfo from './views/ydaddinfo.vue'//充值店铺异店消费总金额充值列表
Vue.use(ElementUI)
Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'login',
      component: login
    },
    {
      path: '/login',
      name: 'login',
      component: login
    },
    {
      path: '/echarts',
      name: 'echarts',
      component: echarts
    },
    {
      path: '/ydaddinfo',
      name: 'ydaddinfo',
      component: ydaddinfo
    },
    {
      path: '/dcash',
      name: 'dcash',
      component: dcash
    }, {
      path: '/apply',
      name: 'apply',
      component: apply
    },
    {
      path: '/db',
      name: 'db',
      component: db
    },
    {
      path: '/feelist',
      name: 'feelist',
      component: feelist
    },
    {
      path: '/level',
      name: 'level',
      component: level
    },
    {
      path: '/dingdan',
      name: 'dingdan',
      component: dingdan
    },
    {
      path: '/online',
      name: 'online',
      component: online
    },
    {
      path: '/xflist',
      name: 'xflist',
      component: xflist
    },
    {
      path: '/shop',
      name: 'shop',
      component: shop
    },
    {
      path: '/store',
      name: 'store',
      component: store
    },
    {
      path: '/storexflist',
      name: 'storexflist',
      component: storexflist
    },
    {
      path: '/vipcard',
      name: 'vipcard',
      component: vipcard
    },
    {
      path: '/vipcardinfo',
      name: 'vipcardinfo',
      component: vipcardinfo
    },
    {
      path: '/orderinfo',//用户列表
      name: 'orderinfo',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/orderinfo.vue')//订单明细
    }
  ]
})
