<template>
  <div class="about">
    <el-container style="height: 500px; border: 1px solid #eee">
      <el-header style="text-align: right; font-size: 12px">
        <tou></tou>
      </el-header>
      <el-container>
        <el-aside width="180px" style="background-color: rgb(238, 241, 246)">
          <leftside></leftside>
        </el-aside>
        <el-main>
          <el-date-picker
            v-model="start"
            type="date"
            placeholder="开始日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            style="width: 100px"
          >
          </el-date-picker>
          至
          <el-date-picker
            v-model="end"
            type="date"
            placeholder="结束日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            style="width: 100px"
          >
          </el-date-picker>
          <label for="" style="margin-left: 10px">支付宝账号：</label>
          <el-input
            placeholder="请输入内容"
            v-model="account"
            style="width: 150px"
            clearable
          >
          </el-input>
          <label for="" style="margin-left: 10px">支付宝姓名：</label>
          <el-input
            placeholder="请输入内容"
            v-model="name"
            style="width: 150px"
            clearable
          >
          </el-input>
          <!-- <label for="" style="margin-left: 10px">店铺名称：</label>
          <el-select v-model="shop_name" filterable @change="zts">
            <el-option :name="0" label="全部" :value="0"> </el-option>
            <el-option
              v-for="items in options"
              :key="items.shop_id"
              :name="items.shop_id"
              :label="items.shop_name"
              :value="items.shop_id"
            >
            </el-option>
          </el-select> -->
          状态：
          <el-select
            v-model="status"
            placeholder="状态"
            @change="zt"
            style="width: 120px"
          >
            <el-option
              v-for="item in option"
              :key="item.value"
              :name="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-button type="warning" @click="cha" style="margin-left: 10px"
            >查看</el-button
          >
          <br /><br />
          <!-- 内容区域 -->
          <Breadcrumb></Breadcrumb>

          <!-- 表格 -->
          <div class="table">
            <el-table :data="tableData" border style="width: 100%">
              <el-table-column fixed prop="id" label="ID"> </el-table-column>
              <el-table-column prop="order_number" label="订单号">
              </el-table-column>
              <el-table-column prop="money" label="申请提现金额">
              </el-table-column>
              <el-table-column prop="commission" label="剩余佣金">
              </el-table-column>
              <el-table-column prop="all_commission" label="累计佣金">
              </el-table-column>
              <el-table-column prop="all_tixian" label="已提现总额">
              </el-table-column>
              <el-table-column prop="nickname" label="用户名">
              </el-table-column>
              <el-table-column prop="account" label="账号"> </el-table-column>
              <el-table-column prop="name" label="支付宝名称">
              </el-table-column>
              <el-table-column prop="identity" label="支付宝账号">
              </el-table-column>
              <!-- <el-table-column
                label="状态"
                prop="status"
                :formatter="stateFormat"
              ></el-table-column> -->
              <el-table-column
                prop="retail"
                header-align="center"
                align="center"
                label="提现来源"
              >
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.retail == 1 ? "新零售" : " " }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="state"
                header-align="center"
                align="center"
                label="状态"
              >
                <template slot-scope="scope">
                  <div :style="{ color: getColorByNation(scope) }">
                    {{
                      scope.row.status == 0
                        ? "未到账"
                        : scope.row.status == 1
                        ? "已到账"
                        : "已拒绝"
                    }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="sub_msg" label="原因"> </el-table-column>
              <el-table-column prop="create_time" label="创建时间">
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="140">
                <template slot-scope="scope">
                  <el-button
                    @click="handleClicks(scope.row)"
                    type="text"
                    size="medium"
                    >查看
                  </el-button>
                  <el-button
                    @click="handleClick(scope.row)"
                    type="text"
                    size="medium"
                    :style="
                      scope.row.status == 0
                        ? 'display:inline-block'
                        : 'display:none'
                    "
                    >同意
                  </el-button>
                  <el-button
                    @click="handleClickn(scope.row)"
                    type="text"
                    size="medium"
                    :style="
                      scope.row.status == 0
                        ? 'display:inline-block'
                        : 'display:none'
                    "
                    >拒绝
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!-- /End 表格 -->
          <div>
            <el-dialog title=" " :visible.sync="visible">
              拒绝原因：
              <el-input
                type="text"
                placeholder="请输入内容"
                style="width: 300px"
                v-model="yuanyin"
                minlength="10"
                show-word-limit
              ></el-input
              ><br /><br />
              <el-button type="success" @click="tijiao">确定</el-button>
              <el-button :plain="true" @click="noti">取消</el-button>
            </el-dialog>
          </div>
          <div>
            <el-dialog title=" " :visible.sync="visibles" width="90%">
              <el-table :data="gridData" height="520">
                <el-table-column fixed prop="id" label="ID"> </el-table-column>
                <el-table-column prop="order_number" label="订单号">
                </el-table-column>
                <el-table-column prop="money" label="金额"> </el-table-column>
                <el-table-column prop="percentage" label="占比(%)">
                </el-table-column>
                <el-table-column prop="commission" label="佣金">
                </el-table-column>
                <el-table-column prop="create_time" label="获取时间">
                </el-table-column>
                <el-table-column prop="user_id" label="用户ID">
                </el-table-column>
                <el-table-column prop="from_way" label="来源">
                </el-table-column>
                <el-table-column prop="pay_money" label="用户支付的现金">
                </el-table-column>
                <el-table-column prop="yihuo" label="用户支付的置呗">
                </el-table-column>
                <el-table-column prop="nick" label="消费者昵称">
                </el-table-column>
                <el-table-column prop="shop_name" label="消费的店铺">
                </el-table-column>
              </el-table>
              <div class="pagination">
                <el-pagination
                  @size-change="handleSizeChanges"
                  @current-change="handleCurrentChanges"
                  :current-page="currentPage"
                  :page-sizes="[20, 40, 60]"
                  :page-size="pagesizes"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="totals"
                >
                </el-pagination>
              </div>
            </el-dialog>
          </div>
          <!-- <Pagination></Pagination> -->

          <!-- 分页 -->
          <div class="pagination">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[20, 40, 60]"
              :page-size="pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
          <!-- /End 分页 -->
        </el-main>
        <!-- /End 内容区域 -->
      </el-container>
    </el-container>
  </div>
</template>

<script>
// import axios from "axios";
import leftside from "../components/leftside.vue";
import tou from "../components/tou.vue";
export default {
  name: "xflist",
  components: {
    leftside,
    tou,
  },
  data() {
    return {
      start: "",
      end: "",
      name: "", //支付宝姓名
      account: "", //支付宝账号
      nickname: "",
      shop_name: "",
      title: "",
      id: this.$route.query.id,
      gridData: [],
      myInstance: null, // axios实例
      tableData: [], // 表格数据
      visible: false,
      visibles: false,
      currentPage: 1, // 当前页码
      total: 0, // 总条目数
      totals: 0, // 总条目数
      user_id: "",
      query: "", // 查询参数
      pagenum: 1, // 当前页码
      pagesize: 20, // 每页显示条数
      pagenums: 1, // 当前页码
      pagesizes: 20, // 每页显示条数
      status: "", //状态
      statusid: "",
      yuanyin: "",
      yuanyinid: "",
      // options: [],
      // sid: "",
      option: [
        {
          value: "-1",
          label: "已拒绝",
        },
        {
          value: "1",
          label: "已到账",
        },
        {
          value: "0",
          label: "未到账",
        },
      ],
    };
  },
  mounted() {
    this.getUser();
    this.$axios.post(this.$api.shoplist, {}).then((res) => {
      this.options = res.data; // 表格数据
    });
  },
  methods: {
    //改变列表状态颜色
    getColorByNation(scope) {
      if (scope.row.status == 0) {
        return "#ff5722";
      } else if (scope.row.status == 1) {
        return "#26bd2c";
      } else {
        return "#2196f3";
      }
    },

    cha() {
      this.pagenum = 1;
      this.getUser();
    },
    zt(data) {
      this.statusid = data;
      console.log(this.statusid);
    },
    // zts(data) {
    //   this.sid = data;
    //   console.log(this.id);
    // },
    // stateFormat(row) {
    //   if (row.status === 1) {
    //     return "已到账";
    //   } else if (row.status === -1) {
    //     return "已拒绝";
    //   } else {
    //     return "未到账";
    //   }
    // },
    getUser() {
      this.$axios
        .post(this.$api.tixian, {
          page: this.pagenum, // 当前页码
          page_size: this.pagesize, // 每页显示条数
          start_time: this.start,
          end_time: this.end,
          status: this.statusid,
          identity: this.account,
          // shop_id: this.sid,
          name: this.name,
        })
        .then((res) => {
          this.tableData = res.data.list; // 表格数据
          this.total = res.data.count; // 总条目数
        });
    },
    handleClicks(row) {
      console.log(row);
      this.user_id = row.user_id;
      this.spcz();
    },
    spcz() {
      this.$axios
        .post(this.$api.yjlist, {
          page: this.pagenums, // 当前页码
          page_size: this.pagesizes, // 每页显示条数
          user_id: this.user_id,
        })
        .then((res) => {
          this.visibles = true;
          this.gridData = res.data.list; // 表格数据
          this.totals = res.data.count; // 总条目数
        });
    },
    handleClick(row) {
      console.log(row);
      this.$confirm("请您确定同意, 是否继续?", "提示", {
        //弹框确定继续操作
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$axios
            .post(this.$api.yes, {
              id: row.id,
              status: "1",
            })
            .then((res) => {
              if (res.code == 1) {
                //判断是否结算成功
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                this.getUser();
                // location.reload() //刷新页面
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    handleClickn(row) {
      this.visible = true;
      this.yuanyinid = row.id;
      console.log(row);
    },
    tijiao() {
      this.$confirm("是否拒绝, 是否继续?", "提示", {
        //弹框确定继续操作
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$axios
            .post(this.$api.no, {
              id: this.yuanyinid,
              status: "-1",
              refuse_reason: this.yuanyin,
            })
            .then((res) => {
              if (res.code == 1) {
                //判断是否结算成功
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                this.visible = false;
                this.getUser();
                // location.reload() //刷新页面
              }
            });
        })
        .catch(() => {
          this.visible = false;
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    noti() {
      this.visible = false;
    },
    handleSizeChange(val) {
      //改变时
      this.pagesize = val;
      this.getUser();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      //条目改变时
      this.pagenum = val;
      this.getUser();
      console.log(`当前页: ${val}`);
    },
    handleSizeChanges(val) {
      //改变时
      this.pagesizes = val;
      this.spcz();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChanges(val) {
      //条目改变时
      this.pagenums = val;
      this.spcz();
      console.log(`当前页: ${val}`);
    },
  },
  computed: {},
};
</script>
<style scoped>
.spbiao {
  width: 70%;
  left: 12%;
  min-height: 200px;
  position: fixed;
  background: #fff;
  border: 1px solid #ebeef5;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  word-break: break-all;
  top: 15%;
  padding: 2%;
}

.a {
  text-decoration: none;
}

.el-menu-item {
  font-size: 20px;
}

.active {
  color: #409eff !important;
}
.el-header {
  position: relative;
  width: 100%;
  height: 60px;
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  display: block;
  position: absolute;
  left: 0;
  top: 70px;
  bottom: 0;
  color: #333;
}
.el-main {
  position: absolute;
  left: 200px;
  right: 0;
  top: 70px;
  bottom: 0;
  overflow-y: scroll;
}
</style>
