<template>
  <div class="about">
    <el-container style="height: 500px; border: 1px solid #eee">
      <el-header style="text-align: right; font-size: 12px">
        <tou></tou>
      </el-header>
      <el-container>
        <el-aside width="180px" style="background-color: rgb(238, 241, 246)">
          <leftside></leftside>
        </el-aside>
        <el-main>
          <el-date-picker
            v-model="start"
            type="date"
            placeholder="开始日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            style="width: 100px"
          >
          </el-date-picker>
          至
          <el-date-picker
            v-model="end"
            type="date"
            placeholder="结束日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            style="width: 100px"
          >
          </el-date-picker>
          <label for="" style="margin-left: 20px">店铺名称：</label>
          <!-- <el-input
            placeholder="请输入内容"
            v-model="shopname"
            style="width: 170px"
            clearable
          >
          </el-input> -->
          <el-select v-model="shopname" filterable @change="zt">
            <el-option
              v-for="item in option"
              :key="item.shop_id"
              :name="item.shop_id"
              :label="item.shop_name"
              :value="item.shop_id"
            >
            </el-option>
          </el-select>
          <!-- <label for="" style="margin-left:20px">姓名：</label>
          <el-input
            placeholder="请输入内容"
            v-model="nameinput"
            style="width:150px"
            clearable
          >
          </el-input> -->
          <el-button type="warning" @click="cha" style="margin-left: 10px"
            >查看</el-button
          >
          <el-button type="warning" @click="handleExcel" style="margin-left: 10px"
            >导出</el-button
          >
          <br /><br />
          <h5>汇总：{{ totals }}元</h5>
          <!-- 内容区域 -->
          <Breadcrumb></Breadcrumb>

          <!-- 表格 -->
          <div class="table">
            <el-table id="out-table" :data="tableData" border style="width: 100%">
              <el-table-column fixed prop="shop_id" label="编号"> </el-table-column>
              <el-table-column prop="shop_name" label="商家"> </el-table-column>
              <el-table-column prop="total_out_money" :label="'调出到' + name">
              </el-table-column>
              <el-table-column prop="total_in_money" :label="  '从'+name+'调入'">
              </el-table-column>
              <el-table-column prop="top_in_bottom_consume" :label="name + '卡本店消费'">
              </el-table-column>
              <el-table-column
                prop="bottom_in_top_consume"
                :label="'本店卡' + name + '消费'"
              >
              </el-table-column>
              <el-table-column prop="settlement_money" label="结算金额">
              </el-table-column>
            </el-table>
          </div>
          <!-- /End 表格 -->

          <!-- <Pagination></Pagination> -->

          <!-- 分页 -->
          <!-- <div class="pagination">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[20, 40, 60]"
              :page-size="pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div> -->
          <!-- /End 分页 -->
          <!-- /End 内容区域 -->
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
// import axios from "axios";
import leftside from "../components/leftside.vue";
import tou from "../components/tou.vue";
export default {
  name: "db",
  components: {
    leftside,
    tou,
  },
  data() {
    return {
      defaultImg: require("../../static/1.png"),
      start: "",
      end: "",
      cardinput: "",
      nameinput: "",
      gridData: [],
      myInstance: null, // axios实例
      tableData: [], // 表格数据
      visible: false,
      currentPage: 1, // 当前页码
      total: 0, // 总条目数
      query: "", // 查询参数
      pagenum: 1, // 当前页码
      pagesize: 20, // 每页显示条数
      option: [],
      sid: "",
      shopname: "",
      name: "",
      totals: 0,
    };
  },
  mounted() {
    this.getUser();
    this.$axios.post(this.$api.shoplist, { type_id:3}).then((res) => {
      this.option = res.data; // 表格数据
    });
  },
  methods: {
    handleExcel() {
      let fields = {
        title0: "ID",
        title1: "商家",
        title2: "商品名",
        title3: "数量",
        title4: "规格",
        title5: "赠送积分",
        title6: "易货额",
        title7: "易货现金",
        title8: "折扣现金",
        title9: "时间",
        title10: "入库员",
      };
      let arry = [];
      let params = {};
      params.page = 1;
      params.pageSize = 999; //这里也可以直接通过接口返回的total，拿值
      this.$axios
        .post(this.$api.daogoods, {
          title: this.input,
          shop_id: this.sid,
          start_time: this.start,
          end_time: this.end,
        })
        .then((res) => {
          console.log(res);
          res.data.forEach((ele) => {
            arry.push({
              title0: ele.goods_id,
              title1: ele.shop_name,
              title2: ele.title,
              title3: ele.num,
              title4: ele.guige,
              title5: ele.give_integral,
              title6: ele.price,
              title7: ele.xj,
              title8: ele.zk_xj,
              title9: ele.create_time,
              title10: ele.nickname,
            });
          });
          console.log(arry);
          console.log(fields);
          this.$xlsx.xlsx(arry, fields, "excel统计列表");
        });
    },
    zt(data) {
      this.sid = data;
      var obj = {};
      obj = this.option.find(function (item) {
        return item.shop_id === data;
      });
      this.name = obj.shop_name;
      //obj 就是被选中的那个对象，也就能拿到label值了。
      console.log(obj.shop_name); //label值
      console.log(data); //value值
    },
    defImg() {
      let img = event.srcElement;
      img.src = this.defaultImg;
      img.onerror = null; //防止闪图
    },
    cha() {
      this.pagenum = 1;
      this.getUser();
    },
    getUser() {
      this.$axios
        .post(this.$api.db, {
          shop_id: this.sid,
          start_time: this.start,
          end_time: this.end,
        })
        .then((res) => {
          this.tableData = res.data.list; // 表格数据
          console.log(res.data.list);
          this.total = res.data.count; // 总条目数
          this.totals = res.data.total_settlement; //零售汇总
        });
    },

    handleClick(row) {
      console.log(row);
      this.$axios
        .post(this.$api.card, {
          id: row.user_id,
        })
        .then((res) => {
          this.visible = true;
          this.gridData = res.data; // 表格数据
        });
    },
    handleSizeChange(val) {
      //改变时
      this.pagesize = val;
      this.getUser();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      //条目改变时
      this.pagenum = val;
      this.getUser();
      console.log(`当前页: ${val}`);
    },
  },
  computed: {},
};
</script>
<style scoped>
.spbiao {
  width: 70%;
  left: 12%;
  min-height: 200px;
  position: fixed;
  background: #fff;
  border: 1px solid #ebeef5;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  word-break: break-all;
  top: 15%;
  padding: 2%;
}

.a {
  text-decoration: none;
}

.el-menu-item {
  font-size: 20px;
}

.el-header {
  position: relative;
  width: 100%;
  height: 60px;
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  display: block;
  position: absolute;
  left: 0;
  top: 70px;
  bottom: 0;
  color: #333;
}
.el-main {
  position: absolute;
  left: 200px;
  right: 0;
  top: 70px;
  bottom: 0;
  overflow-y: scroll;
}
.active {
  color: #409eff !important;
}
</style>
