<template>
  <div class="about">
    <el-container style="height: 500px; border: 1px solid #eee">
      <el-header style="text-align: right; font-size: 12px">
        <tou></tou>
      </el-header>
      <el-container>
        <el-aside width="180px" style="background-color: rgb(238, 241, 246)">
          <leftside></leftside>
        </el-aside>
        <el-main>
          <el-date-picker
            v-model="start"
            type="date"
            placeholder="开始日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            style="width: 100px"
          >
          </el-date-picker>
          至
          <el-date-picker
            v-model="end"
            type="date"
            placeholder="结束日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            style="width: 100px"
          >
          </el-date-picker>

          <label for="" style="margin-left: 10px">商家名称：</label>
          <el-select
            v-model="shop_name"
            filterable
            @change="zt"
            style="width: 120px"
          >
            <el-option :name="0" label="全部" :value="0"> </el-option>
            <el-option
              v-for="item in option"
              :key="item.shop_id"
              :name="item.shop_id"
              :label="item.shop_name"
              :value="item.shop_id"
            >
            </el-option>
          </el-select>
          <label for="" style="margin-left: 10px">支付方式：</label>
          <el-select
            v-model="pay_type"
            filterable
            @change="zts"
            style="width: 120px"
          >
            <el-option :name="0" label="全部" :value="0"> </el-option>
            <el-option
              v-for="item in options"
              :key="item.shop_id"
              :name="item.shop_id"
              :label="item.shop_name"
              :value="item.shop_id"
            >
            </el-option>
          </el-select>
          <label for="" style="margin-left: 10px">消费者微信：</label>
          <el-input
            placeholder="请输入内容"
            v-model="wxchat"
            style="width: 120px"
            clearable
          >
          </el-input>
          <label for="" style="margin-left: 10px">电话：</label>
          <el-input
            placeholder="请输入内容"
            v-model="mobile"
            style="width: 120px"
            clearable
          >
          </el-input>
          <label for="" style="margin-left: 10px"> 卡号：</label>
          <el-input
            placeholder="请输入内容"
            v-model="cardnum"
            style="width: 120px"
            clearable
          >
          </el-input>
          <el-button type="warning" @click="cha" style="margin-left: 10px"
            >查看</el-button
          >
          <br /><br />
          <!-- 内容区域 -->
          <Breadcrumb></Breadcrumb>
          <h5>总现金：{{ totalxj }}元</h5>
          <h5>总易货额：{{ totalyh }} 元</h5>
          <!-- 表格 -->
          <div class="table">
            <el-table :data="tableData" border style="width: 100%">
              <!-- <el-table-column property="id" label="编码"></el-table-column> -->
              <el-table-column
                property="shop_name"
                label="商家名称"
              ></el-table-column>
              <el-table-column
                property="nickname"
                label="消费者微信昵称"
              ></el-table-column>
              <el-table-column property="photo" label="微信头像">
                <template slot-scope="scope">
                  <img
                    :src="scope.row.face"
                    @error="defImg()"
                    width="40"
                    height="40"
                  />
                </template>
              </el-table-column>
              <el-table-column
                property="yihuo_quota"
                label="易货额"
              ></el-table-column>
              <el-table-column
                property="pay_money"
                label="现金额"
              ></el-table-column>
              <el-table-column
                label="支付方式"
                prop="pay_way"
                :formatter="stateFormat"
              ></el-table-column>
              <el-table-column
                label="支付状态"
                prop="alipay_status"
                :formatter="stateFormats"
              ></el-table-column>
              <el-table-column
                property="total_pay"
                label="总额"
              ></el-table-column>
              <el-table-column
                property="account"
                label="消费者电话"
              ></el-table-column>
              <el-table-column
                property="cardnum"
                label="消费者卡号"
              ></el-table-column>
              <el-table-column
                property="before_money"
                label="消费卡原额度"
              ></el-table-column>
              <el-table-column
                property="create_time"
                label="时间"
              ></el-table-column>
              <!-- <el-table-column fixed="right" label="操作" width="100">
                  <template slot-scope="scope">
                    <el-button
                      @click="handleClick(scope.row)"
                      type="text"
                      size="medium"
                      >查看
                    </el-button>
                  </template>
                </el-table-column> -->
            </el-table>
          </div>
          <!-- /End 表格 -->
          <div>
            <el-dialog title=" " :visible.sync="visible">
              <el-table :data="gridData" height="520">
                <el-table-column
                  property="shop_id"
                  label="编码"
                ></el-table-column>
                <el-table-column
                  property="shop_name"
                  label="商户名称"
                ></el-table-column>
                <el-table-column property="photo" label="商品">
                  <template slot-scope="scope">
                    <img :src="scope.row.photo" width="40" height="40" />
                  </template>
                </el-table-column>
                <el-table-column
                  property="mobile"
                  label="电话"
                ></el-table-column>
                <el-table-column
                  property="contact"
                  label="负责人"
                ></el-table-column>
                <el-table-column property="addr" label="地址"></el-table-column>
                <el-table-column
                  property="create_time"
                  label="创建时间"
                ></el-table-column>
              </el-table>
            </el-dialog>
          </div>

          <!-- <Pagination></Pagination> -->

          <!-- 分页 -->
          <div class="pagination">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[20, 40, 60]"
              :page-size="pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
          <!-- /End 分页 -->
        </el-main>
        <!-- /End 内容区域 -->
      </el-container>
    </el-container>
  </div>
</template>

<script>
// import axios from "axios";
import leftside from "../components/leftside.vue";
import tou from "../components/tou.vue";
export default {
  name: "xflist",
  components: {
    leftside,
    tou,
  },
  data() {
    return {
      defaultImg: require("../../static/1.png"),
      start: "",
      end: "",
      wxchat: "",
      cardnum: "",
      shop_name: "",
      mobile: "",
      id: this.$route.query.id,
      gridData: [],
      myInstance: null, // axios实例
      tableData: [], // 表格数据
      visible: false,
      currentPage: 1, // 当前页码
      total: 0, // 总条目数
      query: "", // 查询参数
      pagenum: 1, // 当前页码
      pagesize: 20, // 每页显示条数
      option: [],
      sid: "0",
      pay_type: "",
      pay_typeid: "0",
      options: [
        { shop_id: 1, shop_name: "微信" },
        { shop_id: 2, shop_name: "支付宝" },
      ],
      totalxj: 0,
      totalyh: 0,
    };
  },
  mounted() {
    this.getUser();
    this.$axios.post(this.$api.shoplist, {}).then((res) => {
      this.option = res.data; // 表格数据
    });
  },
  methods: {
    zt(data) {
      this.sid = data;
      console.log(this.id);
    },
    zts(data) {
      this.pay_typeid = data;
      console.log(this.pay_typeid);
    },
    stateFormat(row) {
      if (row.pay_way === 1) {
        return "微信";
      } else if (row.pay_way === 2) {
        return "支付宝";
      }
    },
    stateFormats(row) {
      if (row.alipay_status === 0) {
        return "未支付";
      } else if (row.alipay_status === 1) {
        return "已支付";
      }else{
        return "默认"; 
      }
    },
    defImg() {
      let img = event.srcElement;
      img.src = this.defaultImg;
      img.onerror = null; //防止闪图
    },
    // checkTime(i){
    //   if(i<10){
    //     i='0'+i
    //     }
    //     return i
    //     },
    cha() {
      this.pagenum = 1;
      this.getUser();
    },
    getUser() {
      this.$axios
        .post(this.$api.storexflist, {
          mobile: this.mobile, //电话
          wxchat: this.wxchat, //微信
          shop_id: this.sid.toString(), //店铺id
          pay_typeid: this.pay_typeid.toString(), //支付方式
          cardnum: this.cardnum, //卡号
          page: this.pagenum, // 当前页码
          page_size: this.pagesize, // 每页显示条数
          start_time: this.start, //开始时间
          end_time: this.end, //截止时间
        })
        .then((res) => {
          this.tableData = res.data.list; // 表格数据
          this.total = res.data.count; // 总条目数
          this.totalxj = res.data.total_money; //总现金
          this.totalyh = res.data.total_yihuo; //总易货
        });
    },

    handleClick(row) {
      this.visible = true;
      console.log(row);
      this.$axios
        .post(this.$api.handleClick, {
          order_id: row.shop_id,
        })
        .then((res) => {
          this.visible = true;
          this.gridData = res.data; // 表格数据
        });
    },
    handleSizeChange(val) {
      //改变时
      this.pagesize = val;
      this.getUser();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      //条目改变时
      this.pagenum = val;
      this.getUser();
      console.log(`当前页: ${val}`);
    },
  },
  computed: {},
};
</script>
<style scoped>
.spbiao {
  width: 70%;
  left: 12%;
  min-height: 200px;
  position: fixed;
  background: #fff;
  border: 1px solid #ebeef5;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  word-break: break-all;
  top: 15%;
  padding: 2%;
}

.a {
  text-decoration: none;
}

.el-menu-item {
  font-size: 20px;
}

.active {
  color: #409eff !important;
}
.el-header {
  position: relative;
  width: 100%;
  height: 60px;
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  display: block;
  position: absolute;
  left: 0;
  top: 70px;
  bottom: 0;
  color: #333;
}
.el-main {
  position: absolute;
  left: 200px;
  right: 0;
  top: 70px;
  bottom: 0;
  overflow-y: scroll;
}
h5 {
  display: inline-block;
  width: 30%;
}
</style>
