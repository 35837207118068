<template>
  <div class="about">
    <el-container style="height: 500px; border: 1px solid #eee">
      <el-header style="text-align: right; font-size: 12px">
        <tou></tou>
      </el-header>
      <el-container>
        <el-aside width="180px" style="background-color: rgb(238, 241, 246)">
          <leftside></leftside>
        </el-aside>
        <el-main>
          <el-date-picker
            v-model="start"
            type="date"
            placeholder="开始日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            style="width: 100px"
          >
          </el-date-picker>
          至
          <el-date-picker
            v-model="end"
            type="date"
            placeholder="结束日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            style="width: 100px"
          >
          </el-date-picker>
          <label for="" style="margin-left: 20px">手机号：</label>
          <el-input
            placeholder="请输入内容"
            v-model="input"
            style="width: 150px"
            clearable
          >
          </el-input>
          <label for="" style="margin-left: 20px">商家名称：</label>
          <!-- <el-input
            placeholder="请输入内容"
            v-model="cardinput"
            style="width: 150px"
            clearable
          >
          </el-input> -->
          <el-select
            v-model="cardinput"
            filterable
            @change="zt"
            style="width: 150px"
          >
            <el-option :name="0" label="全部" :value="0"> </el-option>
            <el-option
              v-for="item in option"
              :key="item.shop_id"
              :name="item.shop_id"
              :label="item.shop_name"
              :value="item.shop_id"
            >
            </el-option>
          </el-select>
          <label for="" style="margin-left: 20px">网点类型：</label>
          <el-select
            v-model="shoptype"
            filterable
            @change="zts"
            style="width: 150px"
          >
            <el-option :name="0" label="全部" :value="0"> </el-option>
            <el-option
              v-for="item in options"
              :key="item.shop_id"
              :name="item.shop_id"
              :label="item.shop_name"
              :value="item.shop_id"
            >
            </el-option>
          </el-select>
          <label for="" style="margin-left: 20px"> 联系人：</label>
          <el-input
            placeholder="请输入内容"
            v-model="nameinput"
            style="width: 120px"
            clearable
          >
          </el-input>
          <el-button type="warning" @click="cha" style="margin-left: 10px"
            >查看</el-button
          >
          <br /><br />
          <!-- 内容区域 -->
          <!-- <el-main id="main" class="main"> -->
          <Breadcrumb></Breadcrumb>

          <!-- 表格 -->
          <div class="table">
            <el-table :data="tableData" border style="width: 100%">
              <el-table-column
                property="shop_id"
                label="编码"
              ></el-table-column>
              <el-table-column
                property="shop_name"
                label="商户名称"
              ></el-table-column>
              <el-table-column property="logo" label="商家图片">
                <template slot-scope="scope">
                  <img
                    :src="scope.row.photo"
                    @error="defImg()"
                    width="40"
                    height="40"
                  />
                </template>
              </el-table-column>
              <!-- <el-table-column property="goods_num" label="商品数量"></el-table-column> -->
              <el-table-column property="xj" label="所收现金"></el-table-column>
              <el-table-column
                property="mobile"
                label="商家电话"
              ></el-table-column>
               <el-table-column
                property="total_another_pay"
                label="异店消费总额"
              ></el-table-column>
               <el-table-column
                property="one_another_pay"
                label="异店消费单次限额"
              ></el-table-column>
              <el-table-column
                property="contact"
                label="负责人"
              ></el-table-column>
              <el-table-column
                property="addr"
                label="商铺地址"
              ></el-table-column>
              <el-table-column
                property="create_time"
                label="创建时间"
              ></el-table-column>
              <el-table-column fixed="right" label="操作" width="100">
                <template slot-scope="scope">
                  <el-button
                    @click="handleClic(scope.row)"
                    style="margin: 0"
                    type="text"
                    >充值</el-button
                  >
                  <router-link
                    :to="{
                      path: '/ydaddinfo',
                      query: { id: scope.row.shop_id },
                    }"
                    class="a"
                  >
                    <el-button type="text">充值记录</el-button>
                  </router-link>
                  <el-button
                    @click="handleClick(scope.row)"
                    type="text"
                    size="medium"
                  >
                    <a
                      :href="
                        'https://www.zhilongyihuo.com/mcenter/member/dblog&shop_id=' +
                        shop_id
                      "
                      target="view_window"
                      class="a"
                      >查看调拨</a
                    >
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!-- /End 表格 -->

          <div>
            <el-popover
              placement="left"
              width="780px"
              trigger="manual"
              :offset="300"
              v-model="visible"
              ><br /><br />
              <div class="spbiao">
                <!-- 收款金额：
            <el-input
              v-model="yingshou"
              placeholder="请输入内容"
              style="width: 300px"
            ></el-input
            ><br />
            <br /> -->
                充值金额：
                <el-input
                  v-model="zhifu"
                  placeholder="请输入内容"
                  style="width: 300px"
                ></el-input
                ><br /><br />
                <span style="color: red">*扣款请输入负值*</span><br /><br />
                <el-button type="success" @click="jiesuan">确定</el-button>
                <el-button :plain="true" @click="nopay">取消</el-button>
              </div>
            </el-popover>
          </div>

          <!-- <Pagination></Pagination> -->

          <!-- 分页 -->
          <div class="pagination">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[20, 40, 60]"
              :page-size="pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
          <!-- /End 分页 -->
          <!-- </el-main> -->
          <!-- /End 内容区域 -->
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
// import axios from "axios";
import leftside from "../components/leftside.vue";
import tou from "../components/tou.vue";
export default {
  name: "retail",
  components: {
    leftside,
    tou,
  },
  data() {
    return {
      defaultImg: require("../../static/1.png"),
      start: "",
      end: "",
      input: "",
      cardinput: "",
      id: "0",
      nameinput: "",
      gridData: [],
      myInstance: null, // axios实例
      tableData: [], // 表格数据
      visible: false,
      currentPage: 1, // 当前页码
      total: 0, // 总条目数
      query: "", // 查询参数
      pagenum: 1, // 当前页码
      pagesize: 20, // 每页显示条数
      shop_id: "",
      option: [],
      shoptype: "", //网点类型
      shoptyid: "0",
      zhifu: "",
      options: [
        { shop_id: 2, shop_name: "商家" },
        { shop_id: 3, shop_name: "网点" },
        { shop_id: 5, shop_name: "折扣仓" },
        { shop_id: 6, shop_name: "企业" },
      ],
    };
  },
  mounted() {
    this.getUser();
    this.$axios.post(this.$api.shoplist, {}).then((res) => {
      this.option = res.data; // 表格数据
    });
  },
  methods: {
    zt(data) {
      this.id = data;
      console.log(this.id);
    },
    zts(data) {
      this.shoptyid = data;
      console.log(this.shoptyid);
    },
    defImg() {
      let img = event.srcElement;
      img.src = this.defaultImg;
      img.onerror = null; //防止闪图
    },
    // checkTime(i){
    //   if(i<10){
    //     i='0'+i
    //     }
    //     return i
    //     },
    cha() {
      this.pagenum = 1;
      this.getUser();
    },
    getUser() {
      this.$axios
        .post(this.$api.getUser, {
          // shop_name: this.cardinput, // 查询参数
          shop_id: this.id.toString(),
          mobile: this.input,
          contact: this.nameinput,
          page: this.pagenum, // 当前页码
          page_size: this.pagesize, // 每页显示条数
          start_time: this.start,
          end_time: this.end,
          type_id: this.shoptyid.toString(),
        })
        .then((res) => {
          this.tableData = res.data.list; // 表格数据
          this.total = res.data.count; // 总条目数
        });
    },
    jiesuan() {
      //最终商品结算
      this.$confirm("请您确定, 是否继续?", "提示", {
        //弹框确定继续操作
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$axios
            .post(this.$api.ydadd, {
              shop_id: this.shop_id,
              money: this.zhifu,
            })
            .then((res) => {
              if (res.code == 1) {
                //判断是否结算成功
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                this.visible = false;
                // location.reload(); //刷新页面
              } else {
                this.$message({
                  message: res.msg,
                  type: "warning",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
          this.visible = false;
        });
    },
    nopay() {
      //取消结算
      this.$message({
        message: "取消结算",
        type: "warning",
      });
      this.visible = false;
    },
    handleClic(row) {
      console.log(row);
      this.visible = true;
      this.shop_id = row.shop_id;
    },
    handleClick(row) {
      console.log(row);
      this.shop_id = row.shop_id;
    },
    handleSizeChange(val) {
      //改变时
      this.pagesize = val;
      this.getUser();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      //条目改变时
      this.pagenum = val;
      this.getUser();
      console.log(`当前页: ${val}`);
    },
  },
  computed: {},
};
</script>
<style scoped>
.spbiao {
  width: 70%;
  left: 12%;
  min-height: 200px;
  position: fixed;
  background: #fff;
  border: 1px solid #ebeef5;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  word-break: break-all;
  top: 15%;
  padding: 2%;
}

.a {
  text-decoration: none;
}

.el-menu-item {
  font-size: 20px;
}

.el-header {
  position: relative;
  width: 100%;
  height: 60px;
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  display: block;
  position: absolute;
  left: 0;
  top: 70px;
  bottom: 0;
  color: #333;
}
.el-main {
  position: absolute;
  left: 200px;
  right: 0;
  top: 70px;
  bottom: 0;
  overflow-y: scroll;
}
.active {
  color: #409eff !important;
}
</style>
