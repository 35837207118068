/**   
 * api接口统一管理
 */
 
let url
if(window.location.hostname=='localhost'){
    url='https://shouyin.zhilongyihuo.com'
}else{
    url=window.location.protocol+'//shouyin.zhilongyihuo.com'
}
//  const url=window.location.protocol+'//shouyin.zhilongyihuo.com'
//  const url='https://shouyin.zhilongyihuo.com'
//  const url='http://www.sy.cc'
console.log( url)
 const http = {
    login:url+'/admin/index/login',//登录
    getUser:url+'/admin/shop/list',//商家列表
    shopinfo:url+'/admin/user/list',//用户列表
    xflist:url+'/admin/card/orderGoodsList',//消费列表
    dingdan:url+'/admin/order/list',//订单列表
    dingdaninfo:url+'/admin/order/view',//订单详情
    shop:url+'/admin/goods/list',//商品列表
    tixian:url+'/admin/user/commissionWithdrawalList',//佣金提现列表
    yes:url+'/admin/user/agreeOrRefuse',//同意提现
    no:url+'/admin/user/agreeOrRefuse',//拒绝提现
    dengji:url+'/admin/level/list',//用户等级列表
    vipcard:url+'/admin/card/list',//会员卡列表
    addlevel:url+'/admin/level/add',//添加用户等级
    cardinfo:url+'/admin/card/rechargeLog',//会员卡充值记录
    dellevel:url+'/admin/level/del',//删除用户等级
    xiulevel:url+'/admin/level/edit',//修改用户等级
   //  level:url+'/admin/user/levels',//选择等级
    level:url+'/admin/level/all',//选择等级
    setlevel:url+'/admin/user/setLevel',//设置等级
    shoplist:url+'/admin/shop/all',//选择商铺
    feelist:url+'/admin/card/serviceFeeRechargeLog',//服务费充值记录
    daouser:url+'/admin/user/export',//用户导出
    daoorder:url+'/admin/order/export',//订单导出
    daocard:url+'/admin/card/export',//卡号导出
    daogoods:url+'/admin/goods/export',//商品导出
    add:url+'/admin/card/recharge',//充值服务费
    xiupwd:url+'/admin/card/modifyPass',//修改卡密
    db:url+'/admin/statistics/reconciliation',//对账
    sq:url+'/admin/active/signup',//易货师申请
    tuijian:url+'/admin/user/setRecommender',//设置推荐人
    zhexian:url+'/admin/statistics/linechart',//折线图
    bing:url+'/admin/statistics/piechart',//饼图
    spcz:url+'/admin/goods/oplist',//商品操作
    onlinelist:url+'/admin/order/mobileOrder',//在线订单
    fahuo:url+'/admin/order/deliver',//发货
    dongjie:url+'/admin/card/freezeMoney',//冻结卡额度
    storexflist:url+'/admin/card/consumeOrder',//商家消费列表
    ydadd:url+'/admin/shop/recharge',//充值店铺异店消费总金额
    ydaddinfo:url+'/admin/shop/rechargeLog',//充值店铺异店消费总金额记录
    yjlist:url+'/admin/user/commissionLog',//佣金获取记录
 }
 export default http;